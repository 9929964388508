
// import React, { useState, useEffect } from 'react';
// import { GoogleMap, Marker, InfoWindow, DirectionsRenderer } from '@react-google-maps/api';
// import { GOOGLE_API_KEY, PUBNUB_PUBLISH_KEY, PUBNUB_SECRET_KEY, PUBNUB_SUBSCRIBE_KEY, REST_API_END_POINT } from 'src/constants/Defaultvalues';
// import PubNub from 'pubnub';
// import Page from 'src/components/Page';
// import { Container } from '@material-ui/core';
// import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
// import truckIcon from "src/assets/delivery.svg";
// import installationIcon from "src/assets/2jKMY8AFOqiToSHy1XkhiN26QCc.svg";
// import axios from 'axios';

// const containerStyle = {
//   width: '100%',
//   height: '500px'
// };

// const center = {
//   lat: 34.0522,
//   lng: -118.2437
// };

// const MapComponent = () => {
//   const [markers, setMarkers] = useState([]);
//   const [apiLoaded, setApiLoaded] = useState(false);
//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const [selectedDriverId, setSelectedDriverId] = useState(null);
//   const [routeData, setRouteData] = useState([]);
//   const [locations, setLocations] = useState([]);
//   const [directions, setDirections] = useState([]);
//   const [originalDriverId, setOriginalDriverId] = useState(null);

//   useEffect(() => {
//     const pubnub = new PubNub({
//       publishKey: PUBNUB_PUBLISH_KEY,
//       subscribeKey: PUBNUB_SUBSCRIBE_KEY,
//       secretKey: PUBNUB_SECRET_KEY
//     });

//     pubnub.addListener({
//       message: (messageEvent) => {
//         console.log('messageEvent--', messageEvent);
//         const { driverId, latitude, longitude, speed, customer_name, address, city, state, zipcode, driver_name } = messageEvent.message;
//         setSelectedDriverId(driverId);
//         console.log(driverId, "sdfsdfsfsfsdfsf");
//         setMarkers((prevMarkers) => {
//           const existingMarker = prevMarkers.find(marker => marker.driverId === driverId);
//           if (existingMarker) {
//             return prevMarkers.map(marker =>
//               marker.driverId === driverId
//                 ? { ...marker, lat: latitude, lng: longitude, speed }
//                 : marker
//             );
//           } else {
//             return [...prevMarkers, { driverId, lat: latitude, lng: longitude, speed, customer_name, address, city, state, zipcode, driver_name }];
//           }
//         });
//       },
//       status: (statusEvent) => {
//         console.log('statusEvent--', statusEvent);
//       },
//       presence: (presenceEvent) => {
//         console.log('presenceEvent--', presenceEvent);
//       }
//     });

//     pubnub.subscribe({ channels: ['driver_location'] });

//     return () => {
//       pubnub.unsubscribeAll();
//     };
//   }, []);

//   useEffect(async () => {
//     try {
//       console.log("selectedDriverId", selectedDriverId);
//       const response = await axios.post(`${REST_API_END_POINT}driver/get-driver-by-userId`, { id: selectedDriverId });
//       if (response.data.status === 1) {
//         console.log("resultttttt5454", response.data.result);
//         setOriginalDriverId(response.data.result[0].id);
//       } else {
//         console.log("not getting data");
//       }
//     } catch (error) {
//       console.log("error", error);
//     }
//   }, [selectedDriverId]);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
//     script.async = true;
//     script.onload = () => setApiLoaded(true);
//     document.body.appendChild(script);
//     return () => {
//       document.body.removeChild(script);
//     };
//   }, []);

//   useEffect(async () => {
//     try {
//       if (originalDriverId) {
//         const response = await axios.post(`${REST_API_END_POINT}route/get-route-data-by-driverId`, { id: originalDriverId });
//         if (response.data.status === 1) {
//           console.log("resultttttt12333", response.data.result);
//           handleRouteSelect(response.data.result[0].route_id);
//         } else {
//           console.log("not getting data / id not found");
//         }
//       }
//     } catch (error) {
//       console.log("error", error);
//     }
//   }, [originalDriverId, routeData]);

//   const handleRouteSelect = async (routeId) => {
//     try {
//       const selectedRouteData = routeData?.find(route => route.value === routeId);
//       fetchLocationData(selectedRouteData?.routeData);
//       console.log("selectedRouteData", selectedRouteData.routeData);
      
//     } catch (error) {
//       console.log('Error', error);
//     }
//   };

//   const getLatandLongFromAddress = async (address) => {
//     try {
//       const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_API_KEY}`);
//       const data = await response.json();
//       if (data.status === 'OK') {
//         const location = data.results[0].geometry.location;
//         console.log('locationnnnnnn', location);
//         return { Latitude: location.lat, Longitude: location.lng };
//       } else {
//         console.error('Geocode was not successful for the following reason:', data.status);
//         return null;
//       }
//     } catch (error) {
//       throw new Error('Geocoding failed. ' + error.message);
//     }
//   };

//   const fetchLocationData = async (installationData) => {
//     try {
//       let installations = [];
//       if (installationData?.length > 0) {
//         for (const data of installationData) {
//           const installationAddress = `${data.address}, ${data.city}, ${data.state}, ${data.zipcode}`;
//           try {
//             console.log("installationAddress", installationAddress);
//             const latLong = await getLatandLongFromAddress(installationAddress);
//             const newData = {
//               ...data,
//               installationAddress: installationAddress,
//               lati: latLong.Latitude,
//               longi: latLong.Longitude
//             };
//             installations.push(newData);
//             console.log("installationssssssssss", installations);
//           } catch (error) {
//             console.error('Error fetching lat/long:', error);
//           }
//         }
//         console.log("installations", installations);
//         setLocations(installations);
//       } else {
//         setLocations([]);
//       }
//     } catch (error) {
//       console.log('Error fetching location data:', error);
//     }
//   };

//   const fetchAcceptedRoutes = async () => {
//     try {
//       if (originalDriverId) {
//         console.log("result123", originalDriverId);
//         const response = await axios.post(`${REST_API_END_POINT}driver/get-accepted-routes-by-driver`, { driverId: originalDriverId });
//         if (response.data.status === 1) {
//           console.log("result123", response.data?.routes?.map(route => ({ label: route.route_name, value: route.route_id, ...route })), "asdfasdf");
//           setRouteData(response.data?.routes?.map(route => ({ label: route.route_name, value: route.route_id, ...route })));
//         } else {
//           setRouteData([]);
//           console.log("result123 not getting");
//           console.log("result123 not getting", originalDriverId);
//         }
//       }
//     } catch (error) {
//       console.log('Error fetching routes', error);
//     }
//   };

//   useEffect(() => {
//     fetchAcceptedRoutes();
//   }, [originalDriverId]);

//   useEffect(() => {
//     if (markers.length > 0 && locations.length > 0) {
//       calculateRoute();
//     }
//   }, [markers, locations]);

//   const calculateRoute = () => {
//     const origin = { lat: markers[0].lat, lng: markers[0].lng };
//     const destination = { lat: locations[locations.length - 1].lati, lng: locations[locations.length - 1].longi };
//     const waypoints = locations.slice(0, -1).map(location => ({
//       location: { lat: location.lati, lng: location.longi },
//       stopover: true
//     }));

//     const directionsService = new window.google.maps.DirectionsService();
//     directionsService.route(
//       {
//         origin,
//         destination,
//         waypoints,
//         travelMode: window.google.maps.TravelMode.DRIVING
//       },
//       (result, status) => {
//         if (status === window.google.maps.DirectionsStatus.OK) {
//           setDirections(result);
    
//           console.log("result11111", result);
//         } else {
//           console.error(`Directions request failed due to ${status}`);
//         }
//       }
//     );
//   };

//   console.log('marker----', markers);

//   return (
//     <Page title="Track Driver: Track Driver | Sign-Wise Solution">
//       <Container>
//         <HeaderBreadcrumbs heading={'Track Driver'} />
//         {apiLoaded && (
//           <GoogleMap
//             mapContainerStyle={containerStyle}
//             center={center}
//             zoom={4}
//           >
//             {markers.map(marker => (
//               <Marker
//                 key={marker.driverId}
//                 position={{ lat: marker.lat, lng: marker.lng }}
//                 title={`${marker.driver_name} - Speed: ${marker.speed} km/h`}
//                 onClick={() => setSelectedMarker(marker)}
//                 icon={{
//                   url: truckIcon,
//                   scaledSize: new window.google.maps.Size(40, 40)
//                 }}
//               />
//             ))}

//             {locations.map((location, index) => (
//               <Marker
//                 key={index}
//                 position={{ lat: location.lati, lng: location.longi }}
//                 icon={{
//                   url: installationIcon,
//                   scaledSize: new window.google.maps.Size(40, 40)
//                 }}
//               />
//             ))}

//             {selectedMarker && (
//               <InfoWindow
//                 position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
//                 onCloseClick={() => setSelectedMarker(null)}
//                 options={{
//                   pixelOffset: new window.google.maps.Size(0, -30),
//                   maxWidth: 200,
//                   styles: {
//                     backgroundColor: 'rgba(0, 0, 0, 0.8)',
//                     color: '#fff',
//                     padding: 10,
//                     borderRadius: 5
//                   }
//                 }}
//               >
//                 <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: '#fff', padding: 10, borderRadius: 5 }}>
//                   <h3>{selectedMarker.driver_name}</h3>
//                   <p>Speed: {selectedMarker.speed} km/h</p>
//                 </div>
//               </InfoWindow>
//             )}

//             {directions && (
//               <DirectionsRenderer
//                 directions={directions}
//                 options={{
//                   polylineOptions: {
//                     strokeColor: '#1ccaff',
//                     strokeOpacity: 0.8,
//                     strokeWeight: 5
//                   },
//                   suppressMarkers: true // This option suppresses default markers
//                 }}
//               />
//             )}
//           </GoogleMap>
//         )}
//       </Container>
//     </Page>
//   );
// };

// export default MapComponent;














import React, { useState, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow, DirectionsRenderer } from '@react-google-maps/api';
import { GOOGLE_API_KEY, PUBNUB_PUBLISH_KEY, PUBNUB_SECRET_KEY, PUBNUB_SUBSCRIBE_KEY, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import PubNub from 'pubnub';
import Page from 'src/components/Page';
import { Container, TextField, CircularProgress, Stack, Button, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import truckIcon from "src/assets/delivery.svg";
import installationIcon from "src/assets/2jKMY8AFOqiToSHy1XkhiN26QCc.svg";
import car from "src/assets/car-removebg-preview.png";
import truck from "src/assets/semi.png";
import axios from 'axios';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: 34.0522,
  lng: -118.2437
};

const MapComponent = ({driverDataaa}) => {
  const [markers, setMarkers] = useState([]);
  const [apiLoaded, setApiLoaded] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [routeData, setRouteData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [directions, setDirections] = useState([]);
  const [originalDriverId, setOriginalDriverId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [selectedOriginalDriverId,setSelectedOriginalDriverId] = useState(null)
  const [updatedRoute,setUpdatedRoute] = useState(false)
  const [driverData, setDriverData] = useState([])

  useEffect(() => {
    const pubnub = new PubNub({
      publishKey: PUBNUB_PUBLISH_KEY,
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      secretKey: PUBNUB_SECRET_KEY
    });

    pubnub.addListener({
      message: (messageEvent) => {
        const { driverId, latitude, longitude, speed, customer_name, address, city, state, zipcode, driver_name } = messageEvent.message;
        setSelectedDriverId(driverId);
        setMarkers((prevMarkers) => {
          const existingMarker = prevMarkers.find(marker => marker.driverId === driverId);
          if (existingMarker) {
            return prevMarkers.map(marker =>
              marker.driverId === driverId
                ? { ...marker, lat: latitude, lng: longitude, speed }
                : marker
            );
          } else {
            return [...prevMarkers, { driverId, lat: latitude, lng: longitude, speed, customer_name, address, city, state, zipcode, driver_name }];
          }
        });
      }
    });

    pubnub.subscribe({ channels: ['driver_location'] });

    return () => {
      pubnub.unsubscribeAll();
    };
  }, []);

  useEffect(async () => {
    try {
      
      const response = await axios.post(`${REST_API_END_POINT}driver/get-driver-by-userId`, { id: selectedDriverId });
      if (response.data.status === 1) {
        setOriginalDriverId(response.data.result[0].id);
        setSelectedOriginalDriverId(response.data.result[0].id)
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [selectedDriverId]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
    script.async = true;
    script.onload = () => setApiLoaded(true);
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };

  }, []);




  useEffect(async () => {
    try {
      if (originalDriverId) {
        const response = await axios.post(`${REST_API_END_POINT}route/get-route-data-by-driverId`, { id: originalDriverId });
        if (response.data.status === 1) {
          handleRouteSelect(response.data.result[0].route_id);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [originalDriverId]);
// }, [originalDriverId, routeData]);


// visibility of initially route and installation 
  const handleRouteSelect = async (routeId) => {
    // try {
    //   const selectedRouteData = routeData.find(route => route.value === routeId);
    //   fetchLocationData(selectedRouteData?.routeData);
    // } catch (error) {
    //   console.log('Error', error);
    // }
    console.log("sdfsd",routeData)
  };

  const getLatandLongFromAddress = async (address) => {
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_API_KEY}`);
      const data = await response.json();
      if (data.status === 'OK') {
        const location = data.results[0].geometry.location;
        return { Latitude: location.lat, Longitude: location.lng };
      } else {
        console.error('Geocode was not successful for the following reason:', data.status);
        return null;
      }
    } catch (error) {
      throw new Error('Geocoding failed. ' + error.message);
    }
  };

  const fetchLocationData = async (installationData) => {
    try {
      let installations = [];
      if (installationData?.length > 0) {
        for (const data of installationData) {
          const installationAddress = `${data.address}, ${data.city}, ${data.state}, ${data.zipcode}`;
          try {
            let latLong = null;
            if(!data.lat && !data.lng){
              latLong = await getLatandLongFromAddress(installationAddress);
            }
            const newData = {
              ...data,
              installationAddress: installationAddress,
              lati: data.lat ? Number(data.lat) : latLong.Latitude,
              longi: data.lng ? Number(data.lng) : latLong.Longitude
            };
            installations.push(newData);
          } catch (error) {
            console.error('Error fetching lat/long:', error);
          }
        }
        setLocations(installations);
      } else {
        setLocations([]);
      }
    } catch (error) {
      console.log('Error fetching location data:', error);
    }
  };

  const fetchAcceptedRoutes = async () => {
    try {
      if (originalDriverId) {
        const response = await axios.post(`${REST_API_END_POINT}driver/get-accepted-routes-by-driver`, { driverId: originalDriverId });
        if (response.data.status === 1) {
          setRouteData((prevRouteData) => {
            const newRoutes = response.data.routes.map(route => ({ label: route.route_name, value: route.route_id, ...route }));
            const uniqueRoutes = [...new Map([...prevRouteData, ...newRoutes].map(route => [route.value, route])).values()];
            return uniqueRoutes;
          });
        } else {
          setRouteData([]);
        }
      }
    } catch (error) {
      console.log('Error fetching routes', error);
    }
  };

  useEffect(() => {
    fetchAcceptedRoutes();
  }, [originalDriverId]);

  useEffect(() => {
    if (markers.length > 0 && locations.length > 0) {
      calculateRoute();
    }
  }, [markers, locations,selectedRoute]);

  const calculateRoute = () => {
    console.log("markers1234567890",markers[0])
    console.log("locations1234567890",locations)
    const origin = { lat: markers[0].lat, lng: markers[0].lng };
    const destination = { lat: locations[locations.length - 1].lati, lng: locations[locations.length - 1].longi };
    const waypoints = locations.slice(0, -1).map(location => ({
      location: { lat: location.lati, lng: location.longi },
      stopover: true
    }));

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
          console.log("result123456",result)
        } else {
          console.error(`Directions request failed due to ${status}`);
        }
      }
    );
  };

  console.log('marker----', markers);
  const handleRefreshLocation = async (selected) => {
    if (originalDriverId) {
      const response = await axios.post(`${REST_API_END_POINT}driver/get-accepted-routes-by-driver`, { driverId: originalDriverId });
      if (response.data.status === 1) {
        setRouteData((prevRouteData) => {
          const newRoutes = response.data.routes.map(route => ({ label: route.route_name, value: route.route_id, ...route }));
          const uniqueRoutes = [...new Map([...prevRouteData, ...newRoutes].map(route => [route.value, route])).values()];
          console.log('uniqueRoutes-uniqueRoutes---',uniqueRoutes)
          console.log('uniqueRoutes-selected---',selected)
          const newdata = uniqueRoutes?.find(item=>item.route_id==selected[0]?.route_id)?.routeData;
          console.log('uniqueRoutes-selected route_id:', selected[0]?.route_id);
          console.log('uniqueRoutes-matching route_id:', uniqueRoutes?.map(route => route.route_id));
          console.log('uniqueRoutes-newdata---',newdata)
          fetchLocationData(newdata);
          setSelectedRoute(newdata);
          return uniqueRoutes;
        });
      } else {
        setRouteData([]);
      }
    }
  }


  // useEffect(()=>{
  //   fetchLocationData(selectedRoute) 
  // },[updatedRoute])

  return (
    <Page title="Track Driver: Track Driver | Sign-Wise Solution">
      <Container>
        <HeaderBreadcrumbs heading={'Track Driver'} />
        {/* <Autocomplete
        sx={{mb:2}}
          options={routeData}
          getOptionLabel={(option) => option.route_name}
          onChange={(event, newValue) => {
            if(newValue){
             setSelectedRoute(newValue.routeData);
             fetchLocationData(newValue.routeData) 
            //  handleRouteSelect(newValue.route_id);
             setOriginalDriverId(newValue.routeData[0].driver_id)
             console.log("newValue",newValue)
            }else {
              fetchLocationData(null) 
             handleRouteSelect(null);
             setOriginalDriverId(null)
             setDirections(null)
            }
          
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Route"
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        /> */}

<Stack spacing={2} sx={{ mb: 2 }}>
  <Grid container spacing={2}>
    {/* First Autocomplete */}
    <Grid item xs={12} sm={6}>
      <Autocomplete
        options={routeData}
        getOptionLabel={(option) => option.route_name}
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedRoute(newValue.routeData);
            fetchLocationData(newValue.routeData);
            setOriginalDriverId(newValue.routeData[0].driver_id);
            console.log('newValue', newValue);
          } else {
            fetchLocationData(null);
            handleRouteSelect(null);
            setOriginalDriverId(null);
            setDirections(null);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Route"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Grid>

    {/* Second Autocomplete */}
    <Grid item xs={12} sm={6}>
      {/* <Autocomplete
        options={driverData}
        getOptionLabel={(option) => option.name}
        value={selectedDriver}
        onChange={(event, newValue) => {
          if (newValue) {
            setSelectedDriver(newValue)
          } else {
            setSelectedDriver(null)

          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Route 2"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      /> */}

<Autocomplete
  fullWidth
  id="driver-autocomplete"
  options={driverDataaa}
  getOptionLabel={(option) => option.name || ''}
  value={selectedDriver}
  onChange={(event, newValue) => {
    if (newValue) {
      setSelectedDriver(newValue);
    } else {
      setSelectedDriver(null);
    }
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Select a driver"
      variant="outlined"
    />
  )}
/>

    </Grid>
  </Grid>
</Stack>
         <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{mb:2}}>
           <Button variant="contained" onClick={()=>handleRefreshLocation(selectedRoute)}>Refresh Location</Button>
        </Stack>
        {apiLoaded && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={4}
          >
            {markers.map(marker => (
              <Marker
                key={marker.driverId}
                position={{ lat: marker.lat, lng: marker.lng }}
                title={`${marker.driver_name} - Speed: ${marker.speed} km/h`}
                onClick={() => setSelectedMarker(marker)}
                icon={{
                  // url: truckIcon,
                  // url: car,
                  url: truck,
                  scaledSize: new window.google.maps.Size(20, 20)
                }}
              />
            ))}

            {locations.map((location, index) => (
              <Marker
                key={index}
                position={{ lat: location.lati, lng: location.longi }}
                icon={{
                  url: installationIcon,
                  scaledSize: new window.google.maps.Size(40, 40)
                }}
              />
            ))}

            {selectedMarker && (
              <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                onCloseClick={() => setSelectedMarker(null)}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -30),
                  maxWidth: 200,
                  styles: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    color: '#fff',
                    padding: 10,
                    borderRadius: 5
                  }
                }}
              >
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: '#fff', padding: 10, borderRadius: 5 }}>
                  <h3>{selectedMarker.driver_name}</h3>
                  <p>Speed: {Math.round(selectedMarker.speed)} km/h</p>
                  {/* <p>Speed: {selectedMarker.speed} km/h</p> */}
                </div>
              </InfoWindow>
            )}

            {directions && (
              <DirectionsRenderer
                directions={directions}
                options={{
                  polylineOptions: {
                    strokeColor: '#1ccaff',
                    strokeOpacity: 0.8,
                    strokeWeight: 5
                  },
                  suppressMarkers: true // This option suppresses default markers
                }}
              />
            )}
          </GoogleMap>
        )}
      </Container>
    </Page>
  );
};

export default MapComponent;




