// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`
}

export const ROOTS_PCB = '/production-control-board'
export const PATH_SETTINGS = '/settings'
const ROOTS_AUTH = '/auth'
const ROOTS_DOCS = '/docs'
const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_CALL = '/call'
const ROOTS_DESKTOP = '/agent-desktop'
const ROOT_PUBLIC = 'public/privacy-policy'
const ROOT_CAMPAIGN = 'campaign'
const FINISHED_PRODUCTS = 'view-finished-products'
const FINISHED_TASK = 'view-finished-task'
const FINISHED_SUB_TASK = 'view-sub-task'
const ROOT_INSTALLATION_REPORT = 'templateview'
const ROOT_VIEW_INSTALLATION_REPORT = 'view-report-for-app'
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  userRegister: path(ROOTS_AUTH, '/access-invite-link/:id'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  // privacypolicy:path(ROOTS_AUTH,'/privacy-policy'),
}
export const PUBLIC = {
  root: ROOT_PUBLIC,
}
export const CAMPAIGN = {
  root: ROOT_CAMPAIGN,
}
export const VIEW_FINISHED_PRODUCTS={
  root:FINISHED_PRODUCTS
}
export const VIEW_FINISHED_TASK={
  root:FINISHED_TASK
}
export const VIEW_SUB_TASK={
  root:FINISHED_SUB_TASK
}
export const INSTALLATION_REPORT = {
  root: ROOT_INSTALLATION_REPORT,
}
export const VIEW_INSTALLATION_REPORT = {
  root: ROOT_VIEW_INSTALLATION_REPORT,
}
export const PATH_CALL = {
  root: ROOTS_CALL,
}
export const PATH_DESKTOP = {
  root: ROOTS_DESKTOP,
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    // root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),

  client: {
    root: path(ROOTS_DASHBOARD, '/client'),
    profile: path(ROOTS_DASHBOARD, '/client/profile'),
    cards: path(ROOTS_DASHBOARD, '/client/all-clients'),
    vendors: path(ROOTS_DASHBOARD, '/client/all-vendors'),
    list: path(ROOTS_DASHBOARD, '/client/list'),
    newUser: path(ROOTS_DASHBOARD, '/client/new'),
    addClient: path(ROOTS_DASHBOARD, '/client/add/'),
    editById: path(ROOTS_DASHBOARD, '/client/ada-lindgren/edit'),
    useredit: path(ROOTS_DASHBOARD, '/client/edit/:id'),
    account: path(ROOTS_DASHBOARD, '/client/account/:id'),
    // moreinfo: path(ROOTS_DASHBOARD, '/user/moreinfo'),
    // messages: path(ROOTS_DASHBOARD, '/user/messages'),
    requests: path(ROOTS_DASHBOARD, '/client/view-requests'),
    invoice: path(ROOTS_DASHBOARD, '/client/requests-invoice'),
    addticket: path(ROOTS_DASHBOARD, '/client/add-tickets'),
    minimizecall: path(ROOTS_DASHBOARD, '/client/minimize-call'),
    chat: path(ROOTS_DASHBOARD, '/chat'),
    rfqclient: path(ROOTS_DASHBOARD, '/client/rfq-client'),
    jobclient: path(ROOTS_DASHBOARD, '/client/job-client'),
    projectClient: path(ROOTS_DASHBOARD, '/client/project-client'),
    customerType: path(ROOTS_DASHBOARD, '/client/client-type'),

    // documents: path(ROOTS_DASHBOARD, '/user/documents'),
  },
  callCenter: {
    root: path(ROOTS_DASHBOARD, '/call-center'),
    incomingWindow: path(ROOTS_DASHBOARD, '/call-center/incoming-window'),
    analytics: path(ROOTS_DASHBOARD, '/call-center/analytics'),
    accountSetup: path(ROOTS_DASHBOARD, '/call-center/account-setup'),
    teams: path(ROOTS_DASHBOARD, '/call-center/teams'),
    teamaccount: path(ROOTS_DASHBOARD, '/call-center/team-account'),
    departments: path(ROOTS_DASHBOARD, '/call-center/departments'),
    callFlows: path(ROOTS_DASHBOARD, '/call-center/call-flows'),
    callLogs: path(ROOTS_DASHBOARD, '/call-center/call-logs'),
    smsLogs: path(ROOTS_DASHBOARD, '/call-center/sms-logs'),
    recordings: path(ROOTS_DASHBOARD, '/call-center/recordings'),
    monitorCalls: path(ROOTS_DASHBOARD, '/call-center/monitor-calls'),
    callSettings: path(ROOTS_DASHBOARD, '/call-center/call-settings'),
  },
  official: {
    root: path(ROOTS_DASHBOARD, '/official-request'),
    viewRequest: path(ROOTS_DASHBOARD, '/official-request/view/:id'),
    process: path(ROOTS_DASHBOARD, '/official-request/process'),
  },
  design: {
    root: path(ROOTS_DASHBOARD, '/design-order'),
    viewOrder: path(ROOTS_DASHBOARD, '/design-order/view/:id'),
    process: path(ROOTS_DASHBOARD, '/design-order/process'),
  },
  quotation: {
    root: path(ROOTS_DASHBOARD, '/quick-bid'),
    viewRequest: path(ROOTS_DASHBOARD, '/quick-bid/view/:id'),
    
  },
  rfp: {
    root: path(ROOTS_DASHBOARD, '/rfp'),
    viewRequest: path(ROOTS_DASHBOARD, '/rfp/view/:id'),
    
  },
  staffTime: {
    root: path(ROOTS_DASHBOARD, '/staff-time-tracking'),
    // staffPayout:path(ROOTS_DASHBOARD,'/staff-time-tracking/staff-payout/'),
    viewStaffDetails: path(
      ROOTS_DASHBOARD,
      '/staff-time-tracking/view-details/',
    ),
    listTime: path(
      ROOTS_DASHBOARD,
      '/staff-time-tracking/view-details/list-time/',
    ),
  },
  staffPayout: {
    root: path(ROOTS_DASHBOARD, '/staff-payout'),
  },
  staffSchedular: { root: path(ROOTS_DASHBOARD, '/staff-schedular') },
  SkillsAndService: { root: path(ROOTS_DASHBOARD, '/skills-and-service') },
  installation: {
    root: path(ROOTS_DASHBOARD, '/installation'),
    pickup: path(ROOTS_DASHBOARD, '/installation/pickup'),
    shipping: path(ROOTS_DASHBOARD, '/installation/shipping'),
    view: path(ROOTS_DASHBOARD, '/installation/view/'),
    template: path(ROOTS_DASHBOARD, '/installation/template'),
    addtemplate: path(ROOTS_DASHBOARD, '/installation/add-template'),
    templateview: path(ROOTS_DASHBOARD, '/installation/template-view'),
    edit: path(ROOTS_DASHBOARD, '/installation/edit-installation-templates'),
    // viewReportForApp:path(ROOTS_DASHBOARD,'/installation/view-report-for-app')
    // viewRequest:path(ROOTS_DASHBOARD,'/installation/view'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-air-force-1-ndestrukt',
    ),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(
      ROOTS_DASHBOARD,
      '/e-commerce/product/nike-blazer-low-77-vintage/edit',
    ),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    viewproject: path(ROOTS_DASHBOARD, '/blog/view-job/'),
    viewTask: path(ROOTS_DASHBOARD, '/blog/view-task/'),
   
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(
      ROOTS_DASHBOARD,
      '/blog/post/portfolio-review-is-this-portfolio-too-creative',
    ),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
  project: {
    root: path(ROOTS_DASHBOARD, '/job'),
    all: path(ROOTS_DASHBOARD, '/job/all-job'),
    type: path(ROOTS_DASHBOARD, '/job/job-type'),
    category: path(ROOTS_DASHBOARD, '/job/job-category'),
    noCharge: path(ROOTS_DASHBOARD, '/job/no-charge'),
    viewproject: path(ROOTS_DASHBOARD, '/job/view-job/'),
    viewInstallationTask:path(ROOTS_DASHBOARD, '/job/view-installation-task/'),
    jobCost: path(ROOTS_DASHBOARD, '/job/view-job/job-cost'),
    recipeCost : path(ROOTS_DASHBOARD, '/job/view-job/recipe-cost'),
    productProcess: path(ROOTS_DASHBOARD,'/job/view-job/job-cost/onboard-process', ),
    viewTask: path(ROOTS_DASHBOARD, '/job/view-task/'),
  },
  onboard: {
    root: path(ROOTS_DASHBOARD, '/onboard'),
    productWorkflow: path(ROOTS_DASHBOARD, '/onboard/product-workflow'),
    serviceWorkflow: path(ROOTS_DASHBOARD, '/onboard/service-workflow'),
    create: path(ROOTS_DASHBOARD, '/onboard/create'),
    createProductWorkflow: path(ROOTS_DASHBOARD, '/onboard/create-product-workflow'),
    createServiceWorkflow: path(ROOTS_DASHBOARD, '/onboard/create-service-workflow'),
    process: path(ROOTS_DASHBOARD, '/onboard/process'),
    viewworkflow: path(ROOTS_DASHBOARD, '/onboard/view-workflow/:id'),
  },
  customerOrders: {
    root: path(ROOTS_DASHBOARD, '/customer'),
    create: path(ROOTS_DASHBOARD, '/customer/invoice'),
  },
  community: {
    root: path(ROOTS_DASHBOARD, '/project'),
    create: path(ROOTS_DASHBOARD, '/project/add'),
    type: path(ROOTS_DASHBOARD, '/project/type'),
    addtype: path(ROOTS_DASHBOARD, '/project/add-type'),
    rfqCommunity: path(ROOTS_DASHBOARD, '/project/rfq-project'),
    rfqProjects: path(ROOTS_DASHBOARD, '/project/rfq-job'),
  },
  departments: {
    root: path(ROOTS_DASHBOARD, '/departments'),
  },

  signagreements: {
    root: path(ROOTS_DASHBOARD, '/sign-agreements'),
    addagreement: path(ROOTS_DASHBOARD, '/sign-agreements/add-agreements'),
    editagreement: path(ROOTS_DASHBOARD, '/sign-agreements/edit-agreements'),
  },
  customfieldtemplate: {
    root: path(ROOTS_DASHBOARD, '/custom-field-template'),
    create: path(
      ROOTS_DASHBOARD,
      '/custom-field-template/create-custom-templates',
    ),
    edit: path(ROOTS_DASHBOARD, '/custom-field-template/edit-custom-templates'),
  },

  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    main: path(ROOTS_DASHBOARD, '/products/catalog'),
    labour: path(ROOTS_DASHBOARD, '/products/labour-cost-services'),
    labourType: path(ROOTS_DASHBOARD, '/products/labour-cost-services-type'),
    material: path(ROOTS_DASHBOARD, '/products/material-inventory'),
    // viewFinished: path(ROOTS_DASHBOARD, '/products/view-finished-products'),
    category: path(ROOTS_DASHBOARD, '/products/category'),
    subcategory: path(ROOTS_DASHBOARD, '/products/sub-category'),
    recipe:path(ROOTS_DASHBOARD,'/products/recipe'),
    addRecipe:path(ROOTS_DASHBOARD,'/products/add-recipe'),
    // test:path(ROOTS_DASHBOARD,'/products/test'),
    create: path(ROOTS_DASHBOARD, '/products/add-products'),
    creatematerial: path(ROOTS_DASHBOARD, '/products/add-material'),
    uploadMaterial: path(ROOTS_DASHBOARD, '/products/upload-material'),
    edit: path(ROOTS_DASHBOARD, '/products/edit-products'),
    editMaterial: path(ROOTS_DASHBOARD, '/products/edit-material-products'),
  },
  transportation: {
    root: path(ROOTS_DASHBOARD, '/transportation'),
    routes: path(ROOTS_DASHBOARD, '/routes'),
    routeoptimization: path(ROOTS_DASHBOARD, '/route-optimization'),
    createroute: path(ROOTS_DASHBOARD, '/routes/create-route'),
    details: path(ROOTS_DASHBOARD, '/routes/details'),
    drivers: path(ROOTS_DASHBOARD, '/drivers'),
    viewdriver: path(ROOTS_DASHBOARD, '/drivers/view'),
    track: path(ROOTS_DASHBOARD, '/track-drivers'),
    vehicles: path(ROOTS_DASHBOARD, '/vehicles'),
  },
  payments: {
    root: path(ROOTS_DASHBOARD, '/payments'),
    create: path(ROOTS_DASHBOARD, '/payments/add-payments'),
    invoice: path(ROOTS_DASHBOARD, '/payments/invoice'),
  },

  QuestionnaireTemplates: {
    root: path(ROOTS_DASHBOARD, '/Questionnaire-Templates'),
    create: path(
      ROOTS_DASHBOARD,
      '/Questionnaire-Templates/Add-QuestionnaireTemplates',
    ),
    edit: path(
      ROOTS_DASHBOARD,
      '/Questionnaire-Templates/Edit-QuestionnaireTemplates',
    ),
  },

  crm: {
    root: path(ROOTS_DASHBOARD, '/crm'),
    contact: path(ROOTS_DASHBOARD, '/crm/contact'),
    viewcontact: path(ROOTS_DASHBOARD, '/crm/contact/viewcontact'),
    lead: path(ROOTS_DASHBOARD, '/crm/lead'),
    viewlead: path(ROOTS_DASHBOARD, '/crm/lead/viewlead'),
    addlead: path(ROOTS_DASHBOARD, '/crm/lead/add'),
    leadstatus: path(ROOTS_DASHBOARD, '/crm/lead/status'),
    opportunity: path(ROOTS_DASHBOARD, '/crm/opportunity'),
    opportunityinfo: path(ROOTS_DASHBOARD, '/crm/opportunity/info'),
    call: path(ROOTS_DASHBOARD, '/crm/call'),
    sms: path(ROOTS_DASHBOARD, '/crm/sms'),
    ticket: path(ROOTS_DASHBOARD, '/crm/ticket'),
    viewticket: path(ROOTS_DASHBOARD, '/crm/ticket/view'),
    activitylog: path(ROOTS_DASHBOARD, '/crm/ticket/view/activity-log'),
    campaign: path(ROOTS_DASHBOARD, '/crm/campaign'),
    viewcampaign: path(ROOTS_DASHBOARD, '/crm/campaign/viewcampaign'),
    createcampaignform: path(
      ROOTS_DASHBOARD,
      '/crm/campaign/viewcampaign/create-form',
    ),
    viewcampaignform: path(ROOTS_DASHBOARD, '/crm/campaign/view-campaign-form'),
    customfield: path(ROOTS_DASHBOARD, '/crm/campaign/custom-field'),
    contactbuilder: path(ROOTS_DASHBOARD, '/crm/campaign/contact-builder'),
  },
  insight: {
    root: path(ROOTS_DASHBOARD, '/insight'),
  },
  group: {
    root: path(ROOTS_DASHBOARD, '/group'),
  },
  contactTag: {
    root: path(ROOTS_DASHBOARD, '/contact-tag'),
  },
  clockSetting: {
    root: path(ROOTS_DASHBOARD, '/staff-clock-settings'),
  },
  adminAccount: {
    root: path(ROOTS_DASHBOARD, '/Admin-Settings'),
    userRole: path(ROOTS_DASHBOARD, '/Admin-Settings/User-Role'),
    userRoleEdit: path(ROOTS_DASHBOARD, '/Admin-Settings/User-Role-Edit'),
    userRoleAdd: path(ROOTS_DASHBOARD, '/Admin-Settings/Add-User-Role'),
    // userRoleView:path(ROOTS_DASHBOARD,'/Admin-Settings/User-role-view'),
  },

  taxCategory: {
    root: path(ROOTS_DASHBOARD, '/Tax-Categories'),
  },

  inventoryAudit: {
    root: path(ROOTS_DASHBOARD, '/Inventory-Audit'),
    auditPage: path(ROOTS_DASHBOARD, '/Inventory-Audit/audit-page'),
  },

  receiveOrder: {
    root: path(ROOTS_DASHBOARD, '/Receive-Order'),
    create: path(ROOTS_DASHBOARD, '/Receive-Order/create-receive-orders'),
    edit: path(ROOTS_DASHBOARD, '/Receive-Order/edit-receive-orders'),
    // userRole:path(ROOTS_DASHBOARD,'/Admin-Settings/User-Role'),
    // userRoleEdit:path(ROOTS_DASHBOARD,'/Admin-Settings/User-Role-Edit'),
    // userRoleView:path(ROOTS_DASHBOARD,'/Admin-Settings/User-role-view'),
  },
}

export const PATH_DOCS = {
  root: ROOTS_DOCS,
  introduction: path(ROOTS_DOCS, '/introduction'),
  quickstart: path(ROOTS_DOCS, '/quick-start'),
  package: path(ROOTS_DOCS, '/package'),

  // Theme UI
  color: path(ROOTS_DOCS, '/color'),
  typography: path(ROOTS_DOCS, '/typography'),
  icon: path(ROOTS_DOCS, '/icon'),
  shadows: path(ROOTS_DOCS, '/shadows'),
  components: path(ROOTS_DOCS, '/components'),
  settings: path(ROOTS_DOCS, '/settings'),
  tips: path(ROOTS_DOCS, '/tips'),

  // Development
  routing: path(ROOTS_DOCS, '/routing'),
  environmentVariables: path(ROOTS_DOCS, '/environment-variables'),
  stateManagement: path(ROOTS_DOCS, '/state-management'),
  apiCalls: path(ROOTS_DOCS, '/api-calls'),
  analytics: path(ROOTS_DOCS, '/analytics'),
  authentication: path(ROOTS_DOCS, '/authentication'),
  multiLanguage: path(ROOTS_DOCS, '/multi-language'),
  lazyload: path(ROOTS_DOCS, '/lazyload-image'),
  formHelper: path(ROOTS_DOCS, '/form-helper'),

  // Changelog
  support: path(ROOTS_DOCS, '/support'),
  changelog: path(ROOTS_DOCS, '/changelog'),
}
