import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
// import axios from '../utils/axios';
import axios from 'axios';
import { isValidToken, setSession } from '../utils/jwt';
import { REST_API_END_POINT,REST_API } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD, ROOTS_PCB } from 'src/routes/paths';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
const navigate=useNavigate()
  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
       
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const user = JSON.parse(localStorage.getItem('user'))
          if(user.user_type!=1&&user.user_type!=3){
          const response = await axios.post(REST_API+'user/check-active-user',{userId:user.user_id});
            if(response.data.status==1){
              dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: null
              }
            });
            }
            else{
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: false,
                  user
                }
              });
            }
          }
          else{
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: null
              }
            });
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);
//   useEffect(() => {
//     const handlePostMessage = (event) => {
//       const allowedOrigin = `http://localhost:3001`;
//       // const allowedOrigin = `https://${window.location.hostname}`;
//       if (event.origin !== allowedOrigin) return;

//       const { accessToken, user } = event.data;
// console.log("bhjbgbgbfbgfs",event)
//       if (accessToken && user) {
//         // Save login data to localStorage
//         localStorage.setItem('user_id', user.user_id);
//         localStorage.setItem('user_type', user.user_type);
//         localStorage.setItem('user', JSON.stringify(user));
//         localStorage.setItem('accessToken', accessToken);

//         // Set session and dispatch login
//         setSession(accessToken);
//         dispatch({
//           type: 'LOGIN',
//           payload: { user },
//         });

//         console.log('Auto-login successful via postMessage.');
//       } else {
//         console.error('Invalid login data received.');
//       }
//     };

//     window.addEventListener('message', handlePostMessage);

//     return () => {
//       window.removeEventListener('message', handlePostMessage);
//     };
//   }, []);

//   useEffect(() => {
//     console.log('Setting up postMessage listener');
  
//     const handlePostMessage = async (event) => {
//       const allowedDomain = `.signwisesolutions.com`;
// const allowedOrigin = `https://${window.location.hostname}`
//         // console.log('Received message from:', event.origin);
//         // console.log('Received message event:', event);

//         // Validate the origin
//       //   if (!event.origin.endsWith(allowedDomain)) {
//       //     console.error('Blocked message from untrusted origin:', event.origin);
//       //     return;
//       //   }
//       // // const allowedOrigin = `http://localhost:3000`; // Ensure this matches the parent window's origin
//       // console.log('Received message from:', event.origin);
//       // console.log('Received message from:', event);
//       // console.log('Received message from:', allowedOrigin);
  
//       // Validate the origin
//       if (event.origin !== allowedOrigin) {
//         // console.error('Blocked message from untrusted origin:', event.origin);
//         return;
//       }
  
//       // console.log('Event data received:', event.data);
  
//       const { username, password } = event.data;
  
//       if (!username || !password) {
//         console.error('Missing credentials in the message:', event.data);
//         return;
//       }
  
//       console.log('Received credentials. Attempting login...');
  
//       try {
//         // Call the login function with the received credentials
//         await login(username, password);
  
//         console.log('Login successful via postMessage.');
  
//         // Navigate to the dashboard after successful login
//         navigate(PATH_DASHBOARD.root);
//       } catch (error) {
//         console.error('Error during login via postMessage:', error);
//       }
//     };
  
//     window.addEventListener('message', handlePostMessage);
  
//     return () => {
//       window.removeEventListener('message', handlePostMessage);
//     };
//   }, []);

useEffect(() => {
  const handlePostMessage = async (event) => {
    // const allowedOrigin = `http://localhost:3000`; // Master domain
    const allowedOrigin = `https://master.signwisesolutions.com`; // Master domain
    const childOrigin = window.location.origin;
    // Ignore messages originating from the child itself
    if (event.origin === childOrigin) {
      console.warn('Ignored message from child origin:', event.origin);
      return;
    }

    // Validate the origin of the parent
    if (event.origin !== allowedOrigin) {
      console.error('Blocked message from untrusted origin:', event.origin);
      return;
    }

    const { username, password } = event.data;

    if (!username || !password) {
      console.error('Missing credentials in the message:', event.data);
      return;
    }

    try {
      // Call the login function with the received credentials
      await login(username, password);

      // document.body.style.backgroundColor = 'white';
      // navigate(PATH_DASHBOARD.root);
      // Navigate to the dashboard after successful login
      navigate(PATH_DASHBOARD.root);
    } catch (error) {
      console.error('Error during login via postMessage:', error);
    }
  };
  // window?.opener?.postMessage('child_ready', `http://localhost:3000`);
  window?.opener?.postMessage('child_ready', `https://master.signwisesolutions.com`);
  window?.addEventListener('message', handlePostMessage);
 

  return () => {
    window.removeEventListener('message', handlePostMessage);
  };
}, []);

useEffect(() => {
  console.log('Setting up postMessage listener for child window.');

  // Notify parent that the child window is ready
  if (window.opener) {
    window.opener.postMessage('child_ready', window.location.origin);
    // window.opener.postMessage('child_ready', 'http://localhost:3001');
  }

  // Simulate fetching data
  const fetchData = async () => {
    console.log('Fetching data in child window...');
    // Simulate data fetching delay
    await new Promise((resolve) => setTimeout(resolve, 3000)); // Replace with actual data fetching logic

    console.log('Data fetched successfully. Notifying parent...');
    if (window.opener) {
      window.opener.postMessage('data_fetched', window.location.origin);
    }
  };

  fetchData();

  return () => {
    console.log('Cleanup child window listeners.');
  };
}, []);



// useEffect(() => {
//   console.log('Setting up postMessage listener');

//   const handlePostMessage = async (event) => {
//     const allowedDomain = `.signwisesolutions.com`;
//     const allowedOrigin = `https://master.signwisesolutions.com`; // Master domain

//     console.log('Received message from:', event.origin);

//     // Validate the origin
//     if (!event.origin.endsWith(allowedDomain)) {
//       console.error('Blocked message from untrusted domain:', event.origin);
//       return;
//     }

//     if (event.origin !== allowedOrigin) {
//       console.warn('Origin is not master.signwise.com. Retrying...');
//       // Retry by sending the event data back to the `handlePostMessage`
//       handlePostMessage({
//         origin: allowedOrigin,
//         data: event.data, // Pass the same data for retry
//       });
//       return;
//     }

//     console.log('Event data received:', event.data);

//     const { username, password } = event.data;

//     if (!username || !password) {
//       console.error('Missing credentials in the message:', event.data);
//       return;
//     }

//     console.log('Received credentials. Attempting login...');

//     try {
//       // Call the login function with the received credentials
//       await login(username, password);

//       console.log('Login successful via postMessage.');

//       // Navigate to the dashboard after successful login
//       navigate(PATH_DASHBOARD.root);
//     } catch (error) {
//       console.error('Error during login via postMessage:', error);
//     }
//   };

//   window.addEventListener('message', handlePostMessage);

//   return () => {
//     window.removeEventListener('message', handlePostMessage);
//   };
// }, []);




  const login = async (usename, password) => {
    let data ={}
    data.username = usename,
    data.password = password
    const response = await axios.post(REST_API+'login', data);
    // if(response.data.status==1){
      const { accessToken, user } = response.data;  
      if(accessToken){
        localStorage.setItem('user_id', user.user_id);
        localStorage.setItem('user_type', user.user_type);
        localStorage.setItem('user',JSON.stringify(user))
      }
      setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user                                      
        }
      });
    // }
  //  else{

  //  }
                                                                                                                
  
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const fetchTimeLogData = async (userId) => {
    try{
      const res = await axios.post(`${REST_API_END_POINT}project-task/fetch-clockedIn-staff-task-timelog`,{userId})
      if(res.data.status===1){
        const staffTimeLogData = res.data.result
        // console.log('staffTimeLogData---------',staffTimeLogData);
        let clockoutArray = []
        for (const staffTimeLog of staffTimeLogData) {
          const now = Math.round(moment().valueOf() / 1000);
          if(staffTimeLog.end_time === null){
            const timeLogId = staffTimeLog?.id
            const projectId = staffTimeLog?.job_id
            const taskId = staffTimeLog?.task_id
            const startTime = staffTimeLog?.start_time
            const staffId = staffTimeLog?.staff_id
            const staffHourlyRate = staffTimeLog?.staff_hourly_rate || 20
            let res = await axios.post(`${REST_API_END_POINT}project-task/update-staff-task-timelog`,
              // {projectId, userId, timeLogId,taskId})
              { projectId, userId, timeLogId, taskId, startTime, now, staffId, staffHourlyRate})
            if(res.data.status===1){
              // console.log('Clock Out success res : ',res.data.message);
              clockoutArray.push(res.data)
            }else{
              console.log('Clock Out fail res : ',res.data);
            }
          }
        }
        if(clockoutArray?.length === staffTimeLogData?.length){
          console.log('Clocked Out all active subtasks successfully');
        }else{
          console.log("Failed to clock out all subtasks");
        }
      }
    }catch(error){
      console.log('Error fetching clock-in : ',error);
    }
  }

  const logout = async () => {
    setSession(null);
    let userData=JSON.parse(window.localStorage.getItem('user'))
    const response = await axios.post(REST_API+'logout', {
     userId:userData.user_id
    });
    fetchTimeLogData(userData?.user_id)
    if(response.data.status==1){
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_type')
      localStorage.removeItem('user')
      localStorage.removeItem('sidebar_order');
      localStorage.removeItem('permission_data');
      dispatch({ type: 'LOGOUT' })
    }
  
  };

  const resetPassword = (email) => {
    const result = axios.post(REST_API+'forgot-password', { //Link to be changed later
      email,
    })
    return result
  }
  const loginWithUserId=async (userType,id) => {
    // console.log("$$$$$$$$$$$$$$$^^^^^^^^^^^^^^",id);
   
      let data = {}
        data.id=id,
        data.userType = userType
      const response = await axios.post(REST_API + 'login-using-id', data);
      // if(response.data.status==1){
      const { accessToken, user } = response.data;
      if (accessToken) {
       
        localStorage.setItem('user', JSON.stringify(user))
                 
      }
      // setSession(accessToken);
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
  
  }
  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        loginWithUserId
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
