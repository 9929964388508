import PropTypes from 'prop-types'
import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import QRCode from 'react-qr-code'
import ReactToPrint from 'react-to-print'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import { PrintOutlined } from '@material-ui/icons'
// material
import { useTheme } from '@material-ui/core/styles'
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { BASE_URL, COMPANY_NAME } from 'src/constants/Defaultvalues'
import { empty } from 'src/Helpers/Common'

const labelStyle = {
  width: '62mm',
  height: '30mm',
  padding: '1mm',
  fontFamily: 'Arial, sans-serif', fontSize: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxSizing: 'border-box', gap: '2mm', 
  overflow: 'hidden', 
};
const labelStyle1 = {
  width: '0.9in',
  height: '0.9in',
  padding: '1mm',
  fontFamily: 'Arial, sans-serif',
  fontSize: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '2mm',
  overflow: 'hidden',
};

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  gap: 2
};

const rowStyle1 = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

const sectionStyle = {
  display: 'flex',
  flexDirection: 'column',
};

const qrCodeSection = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1mm',
};

const typographyStyle = {
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'black',
  fontSize: '12px',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
};

const typographyStyle1 = {
  textAlign: 'center',
  color: 'black',
  fontSize: '6px',
  wordWrap: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
};

export default function FinishedProductmenu({ id, url, useIcon, name, barcode }) {
  const ref = useRef(null)
  const componentRef = useRef(null)
  const detailsRef = useRef(null);
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  let user = JSON.parse(localStorage.getItem('user'))

  return (
    <>
    {useIcon ? 
      <ReactToPrint
        trigger={() => (
            <IconButton ref={ref} color='#ffffff'>
              <PrintOutlined />
            </IconButton>
        )}
        content={() => componentRef.current}
      /> :
      <>
        <ReactToPrint
          trigger={() => (
              <Tooltip title='Print Label'>
                <IconButton ref={ref} color='#ffffff'>
                  <PrintOutlined />
                </IconButton>
              </Tooltip>
          )}
          content={() => componentRef.current}
        />
        <ReactToPrint
          trigger={() => (
              <Tooltip title='Print Small Label'>
                <IconButton ref={detailsRef} color='primary'>
                  <PrintOutlined />
                </IconButton>
              </Tooltip>
          )}
          content={() => detailsRef.current}
        />
      </>}
      
      <div style={{ display: 'none' }}>
        <div ref={componentRef} style={labelStyle}>
          <div style={rowStyle}>
            {!empty(name) && (
              <>
                <div style={qrCodeSection}>
                  <QRCode
                    value={name}
                    size={50}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}       
                    includeMargin={false}
                  />
                  <Typography variant="caption" sx={{color:'black', textAlign:'center'}}>Scan Material</Typography>
                </div>
              </>
            )}
            {!empty(name) && (
              <div style={sectionStyle}>
                <Typography variant="h5" style={typographyStyle}>{name}</Typography>
              </div>
            )}
            <div style={qrCodeSection}>
              <QRCode
                value={`${url}${id}`}
                size={50}
                bgColor={'#ffffff'}
                fgColor={'#000000'}       
                includeMargin={false}
              />
              <Typography variant="caption" sx={{color:'black', textAlign:'center'}}>Scan for Details</Typography>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <div ref={detailsRef} style={{ ...labelStyle1, margin: '0' }}>
          <div style={rowStyle}>
            {!empty(barcode) && (
              <>
                <div style={qrCodeSection}>
                  <QRCode
                    value={barcode}
                    size={45}
                    bgColor={'#ffffff'}
                    fgColor={'#000000'}       
                    includeMargin={false}
                  />
                  <Typography variant="caption" style={typographyStyle1}>{name}</Typography>
                </div>
              </>
            )}
            {/* <div style={sectionStyle}>
              {!empty(name) && (
                <Typography variant="h5" style={typographyStyle}>{name}</Typography>
              )}
            </div> */}
            {/* <div style={qrCodeSection}>
              <QRCode
                value={`${url}${id}`}
                size={50}
                bgColor={'#ffffff'}
                fgColor={'#000000'}       
                includeMargin={false}
              />
              <Typography variant="caption" sx={{color:'black', textAlign:'center'}}>Scan for Details</Typography>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

FinishedProductmenu.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  useIcon: PropTypes.bool,
  name: PropTypes.string
};
