import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader, Badge } from '@material-ui/core';
import { PATH_DASHBOARD, PATH_DESKTOP } from 'src/routes/paths';
import axios from 'axios';
import { REST_API, REST_API_END_POINT } from 'src/constants/Defaultvalues';
import useWorkSpaceOption from 'src/hooks/useWorkspaceOptions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(2.5),
  // color: theme.palette.text.secondary,  temporary change
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    // backgroundColor: isActiveSub ? theme.palette.primary.main : color || theme.palette.success.main,   temporary change
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func
};

function NavItem({ item, requestCount,installsCount, estimateCount,designCount,pickupCount,shippingCount, active, explodedPermissions, userType,isCompact }) {
  // console.log('NavItem--item--',item)
  const theme = useTheme();
  const userData = JSON.parse(localStorage.getItem('user'))
  const Title = userData.job_title
  const { workSpaceTitle } = useWorkSpaceOption()
  let JOB_TITLE= Title ? Title :'Jobs'
  const { title, path, icon, info, children,color } = item;
  const hasActiveChild = children && children.some(child => active(child.path));
  const isActiveRoot = hasActiveChild || active(item.path);
  const [open, setOpen] = useState(isActiveRoot); //isCompact && 

  const locReqCount = localStorage.getItem('requestCount');
  const locEstCount = localStorage.getItem('estimateCount');
  const locInsCount = localStorage.getItem('installsCount'); 
  const locPicCount = localStorage.getItem('pickupCount'); 
  const locShipCount = localStorage.getItem('shippingCount'); 
  const locDesignCount = localStorage.getItem('designCount'); 

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleListItemClick = (path,event) => {
    if (path.endsWith('/incoming-window')) {
      window.open(PATH_DESKTOP.root, '_blank','width=1000,height=1000');
      event.preventDefault();
    }
  };

  const checkChildPermissions = (permissionChecks, items) => {
    return permissionChecks.map(({ permissions, title }) => {
      const hasAnyPermission = permissions.some((perm) =>
        explodedPermissions.includes(perm)
      );

      if (hasAnyPermission) {
        const filteredItems = items.filter((item) => title === item.title)
        if (filteredItems.length > 0) {
          return filteredItems.map((item) => {
            const { title, path } = item;
            const isActiveSub = active(path);
            const badgeCount =
            title === "Installs" ? (installsCount ||locInsCount) :
            title === "Shipping" ? (shippingCount ||locShipCount) :
            title === "Pickup" ? (pickupCount||locPicCount) : 0;
            return (
              <ListItemStyle
                key={title}
                component={RouterLink}
                onClick={(e)=>handleListItemClick(path,e)}
                to={path}
                sx={{
                  ...(isActiveSub && activeSubStyle)
                }}
              >
                <ListItemIconStyle>
                  <Box
                    component="span"
                    sx={{                     
                      width: 4,
                      height: 4,
                      display: 'flex',
                      borderRadius: '50%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'text.disabled',
                      transition: (theme) => theme.transitions.create('transform'),
                      ...(isActiveSub && {
                        transform: 'scale(2)',
                        bgcolor: 'primary.main'
                      })
                    }}
                  />
                </ListItemIconStyle>
                {/* <ListItemText disableTypography primary={title} /> */}
                {/* {!isCompact && <ListItemText disableTypography primary={title} />} */}
                {!isCompact ? (
                badgeCount && userType !== 1 ? (
                  <Badge badgeContent={badgeCount} color="warning">
                    <ListItemText disableTypography primary={title} />
                  </Badge>
                ) : (
                  <ListItemText disableTypography primary={title} />
                )
              ) :(
                badgeCount && userType !== 1 ? (
                  <Badge badgeContent={badgeCount} color="warning">
                  </Badge>
                ) : (
                  null
                )
              )
            }
              </ListItemStyle>
              
            )
          })
        };
      }
      return null;
    });
  };
  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot ? activeRootStyle : color==='success' ? {bgcolor:theme.palette.success.main} : {})  //temporary
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
         {!isCompact && <ListItemText disableTypography primary={title} />}
          {info && info}
          <Box
            component={Icon}
            icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {userType !== 3 && (
              checkChildPermissions([
                {
                  permissions: ['View Contacts','Add Contact', 'Activate/Deactivate Contact', 'Delete Contact', 'Edit Contact', 'View Contact'],
                  title: 'Contacts',
                },
                {
                  permissions: ['View All Leads','Add Lead', 'Activate/Deactivate Lead', 'Delete Lead', 'View Lead', 'Edit Lead','View Lead Statuses','Add Lead Status','Edit Lead Status','Activate/Deactivate Lead Status','Delete Lead Status'],
                  title: 'Leads',
                },
                {
                  permissions: ['View Opportunities','Add Opportunity', 'Activate/Deactivate Opportunity', 'Delete Opportunity', 'View Opportunity', 'Edit Opportunity'],
                  title: 'Opportunities',
                },
                {
                  permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
                  title: 'Workflow',
                },
                {
                  permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
                  title: 'Production Workflow',
                },
                {
                  permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
                  title: 'Service Workflow',
                },
                {
                  permissions: ['View Custom Field Template','Create Custom Field Template', 'Activate/Deactivate Custom Field Template','Delete Custom Field Template'],
                  title: 'Custom Field Template',
                },
                {
                  permissions: ['View Questionnaire Template','Create Questionnaire Template','Activate/Deactivate Questionnaire Template','Delete Questionnaire Template'],
                  title: 'Questionnaire Templates',
                },
                {
                  permissions: ['View Sign Agreements','Create Sign Agreements', 'Edit Sign Agreements','Activate/Deactivate Sign Agreements','Delete Sign Agreements'],
                  title: 'Sign Agreements',
                },
                {
                  permissions: ['View Departments','Add Department','Activate/Deactivate Department','Delete Department','View Department','Edit Department'],
                  title: 'Departments',
                },
                {
                  permissions: ['View team members','Inviting team members','Add team members','Edit team members','Delete team members'],
                  title: 'Team Members',
                },
                {
                  permissions: ['View All User Roles','Add User Roles','Edit User Roles','Activate/Deactivate User Roles','Delete User Roles'],
                  title: 'User Roles',
                },
                {
                  permissions: ['Add New Installation','View All Installations'],
                  title: 'Installs',
                },
                {
                  permissions: ['Add New Installation','View All Installations'],
                  title: 'Pickup',
                },
                {
                  permissions: ['Add New Installation','View All Installations'],
                  title: 'Shipping',
                },
                {
                  permissions: ['View Installation Templates','Add Installation Templates','Delete Installation Templates'],
                  title: 'Install Template',
                },             
                {
                  permissions: ['Time Tracking'],
                  title: 'Time Tracking',
                },
                {
                  permissions: ['Add Staff Payout','Edit Staff Payout','View All Staff Payout','Payout based on Time-Tracking'],
                  title: 'Staff Payout',
                },
                {
                  permissions: ['View All Staff Schedules','Add Staff Schedule'],
                  title: 'Staff Scheduler',
                },
                {
                  permissions: ['View All Staff Schedules','Add Staff Schedule'],
                  title: 'Skills & Services',
                },
                {
                  permissions: ['Add Email', 'Deactivate Email', 'Delete Email'],
                  title: 'Emails',
                },
                {
                  permissions: ['Add Calendar', 'Deactivate Calendar', 'Delete Calendar'],
                  title: 'Calendar',
                },
                {
                  permissions: ['Add Meeting', 'Deactivate Meeting', 'Delete Meeting',],
                  title: 'Meetings',
                },
                {
                  permissions: ['Call Logs'],
                  title: 'Calls',
                },
                {
                  permissions: ['SMS Logs'],
                  title: 'SMS',
                },
                {
                  permissions: ['View Ticket','Add Ticket', 'Delete Ticket', 'Edit Ticket'],
                  title: 'Tickets',
                },
                {
                  permissions: ['View Campaigns','Add Campaign', 'Activate/Deactivate Campaign', 'Delete Campaign', 'View Campaign', 'Edit Campaign','View Custom Contact Form','Add Custom Contact Form','Delete Custom Contact Form','View Customization form','View/Export Enquiries'],
                  title: 'Campaigns',
                },
                {
                  permissions: ['View Jobs','Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'],
                  title: `All Jobs`,
                },
                {
                  permissions: ['View Job Types','Add Job Types','Edit Job Types','Activate/Deactivate Job Types','Delete Job Types'],
                  title: `Job Class`,
                },
                {
                  permissions: ['View Job Categories','Add Job Categories','Edit Job Categories','Activate/Deactivate Job Categories','Delete Job Categories'],
                  title: `Job Type`,
                },
                {
                  permissions: ['View Job Categories','Add Job Categories','Edit Job Categories','Activate/Deactivate Job Categories','Delete Job Categories'],
                  title: `No charge type`,
                },
                {
                  permissions: ['Calls'],
                  title: 'incoming window',
                },
                {
                  permissions: ['Call Analytics'],
                  title: 'analytics',
                },
                {
                  permissions: ['Manage Call flow','View team members','Inviting team members','Add team members','Edit team members', 'Delete team members','View Departments', 'Add Department', 'Activate/Deactivate Department', 'Delete Department', 'Edit Department'],
                  title: 'account setup',
                },
                {
                  permissions: ['Call Logs'],
                  title: 'call logs',
                },
                {
                  permissions: ['SMS Logs'],
                  title: 'sms logs',
                },
                {
                  permissions: ['Access Call Recordings'],
                  title: 'recordings',
                },
                // {
                //   permissions: ['Monitor Call'],
                //   title: 'monitor calls',
                // },
                // {
                //   permissions: ['Inviting clients', 'Assigning relationship managers'],
                //   title: 'all clients',
                // },
                {
                  permissions: ['Inviting clients','Edit clients','Delete clients','View Clients' ],
                  title: 'All Clients',
                },
                {
                  permissions: ['Inviting clients','Edit clients','Delete clients','View Clients' ],
                  title: 'Client Type',
                },
                {
                  permissions: ['View Catalog','View Catalogs','Add Catalog','Edit Catalog','Activate/Deactivate Catalog','Delete Catalog' ],
                  title: 'Catalog',
                },
                {
                  permissions: ['View all services','Add services','Edit services','Activate/Deactivate Services','Delete Services' ],
                  title: 'Services',
                },
                {
                  permissions: ['View Product Formulas','Add Product Formula','Edit Product Formula','Delete Product Formula'],
                  title: 'Product Formula',
                },
                {
                  permissions: ['View Materials','View Material Label','Add Material Label','Edit Material Label','Activate/Deactivate Material Label','Delete Material Label','View Finished Product','Add Finished Product'],

                  title: 'Material Inventory',
                },
                {
                  permissions: ['Inventory Audit','Edit Inventory','View Inventory Audit Log'],
                  title: 'Inventory Audit',
                },
                {
                  permissions: ['View Categories','Add category','Edit category','Activate/Deactivate category','Delete category'],
                  title: 'Category',
                },
                {
                  permissions: ['View Sub Categories','Add Sub category','Edit Sub category','Activate/Deactivate Sub category','Delete Sub category'],
                  title: 'Sub Category',
                },
                {
                  permissions: ['Transportation'],
                  title: 'Transportation',
                },
                {
                  permissions: ['Manage Routes'],
                  title: 'Routes',
                },
                {
                  permissions: ['Route Optimization'],
                  title: 'Active Routes',
                },
                {
                  permissions: ['Manage Drivers'],
                  title: 'Drivers',
                },
                {
                  permissions: ['Manage Vehicles'],
                  title: 'Vehicles',
                },
                {
                  permissions: ['Track drivers'],
                  title: 'Track Drivers',
                },
                {
                  permissions: ['View Vendors','Add Vendors','Edit Vendors','Delete Vendors'],
                  title: 'All Vendors',
                },
                // {
                //   permissions: ['View Tax category'],
                //   title: 'Tax categories',
                // },
                {
                  permissions: ['View All Orders','Create Order','Edit Order','Cancel Order','View Order','View Invoice','Make payment','Receive Order'],
                  title: 'Receive Inventory Orders',
                },
                {
                  permissions: ['View request','Create Request', 'Edit Request', 'Delete request','Add vendor to request','Add client to request','View Markup'],
                  title: 'Purchase Order',
                },
              ], children)
            )}

            {userType == 1 && (
              checkChildPermissions([
                {
                  permissions: ['Call Settings'],
                  title: 'call settings',
                },
              ], children))}

            {/* {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main'
                        })
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })} */}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <>
      <ListItemStyle
        component={RouterLink}
        to={path}
        sx={{
          ...(isActiveRoot ? activeRootStyle : color==='success' ? {bgcolor:theme.palette.success.main} : {}) //temporary
        }}
      >
        {(isCompact && userType !== 1 && (title == 'Estimate' || title == 'Sales Order'||title =='design order') ) ?
          <Badge badgeContent={ title == 'Estimate' ? (estimateCount || locEstCount) :title == 'Sales Order'? (requestCount || locReqCount):title =='design order' ? (designCount || locDesignCount):'' } color={'warning'}>
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          </Badge>
        :
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        }
        {!isCompact &&
          (((title == 'Estimate' || title == 'Sales Order' || title =='design order') ) && userType !== 1 ?
            <Badge badgeContent={ title == 'Estimate' ? (estimateCount || locEstCount) :title == 'Sales Order' ? (requestCount || locReqCount) :title =='design order' ? (designCount || locDesignCount):'' } color={'warning'}>
              <ListItemText disableTypography primary={title} />
            </Badge>
          :
              <ListItemText disableTypography primary={title} />
          )
        }
        {info && info}
      </ListItemStyle>
      
    </>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array
};

export default function NavSection({ navConfig,isCompact,added, ...other }) {

  const { pathname } = useLocation();

  // const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);
  const match = (path) => {
    if (!path) return false;
  
    const exactPaths = [
      PATH_DASHBOARD.installation.root,
      PATH_DASHBOARD.installation.pickup,
      PATH_DASHBOARD.installation.shipping,
      PATH_DASHBOARD.installation.template,
    ];
  
    const isInstallationView =
      pathname.startsWith(PATH_DASHBOARD.installation.root) &&
      pathname.includes('/view/');
  
    // Mark `PATH_DASHBOARD.installation.root` as active for `view/:id` routes
    if (path === PATH_DASHBOARD.installation.root && isInstallationView) {
      return true;
    }
  
    // Exact matching for specific paths
    if (exactPaths.includes(path)) {
      return pathname === path;
    }
  
    // Default matching for other paths
    return !!matchPath({ path, end: false }, pathname);
  };
  
  
  const [permissionsData,setPermissionsData] = useState([]);
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user);
  const [order, setOrder] = useState([]);

  
  const [requestCount, setReqCount] = useState(() => Number(localStorage.getItem('requestCount')) || 0);
  const [estimateCount, setEstimateCount] = useState(() => Number(localStorage.getItem('estimateCount')) || 0);  
  const [completedInstallationCount,setCompletedInstallationCount]= useState(0)
  const [RFPcount,setRFPCount]= useState(0)
  const [receiveOrderCount,setReceiveOrderCount]= useState(0)
  const [installsCount,setInstalls]=useState(() => Number(localStorage.getItem('installsCount')) || 0)
  const [pickupCount,setPickup]=useState(() => Number(localStorage.getItem('pickupCount')) || 0) 
  const [shippingCount,setShipping]=useState(() => Number(localStorage.getItem('shippingCount')) || 0) 
  const [designCount,setDesign]=useState(() => Number(localStorage.getItem('designCount')) || 0) 
  const fetchDashboardData = async () => {
    try {
      let res = await axios.post(`${REST_API_END_POINT}user/dashboard`, {
        userId: userData.user_id, userType: userData.user_type
      })
      setReqCount(res.data?.requestCounts?.count)
      const newReqCount = res.data?.requestCounts?.count || 0
      if(newReqCount){
        localStorage.setItem('requestCount', newReqCount);
      }else{
        localStorage.setItem('requestCount', 0);
      }
    } catch (error) {
      console.log(error)
      setReqCount(0)
      localStorage.setItem('requestCount', 0);
    }
  }

  const fetchDashboardEstimateData = async () => {
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      let userType = user.user_type
      let userId = user.user_id

      const values = {
        userType: userType, 
        userId: userId, 
        page: 1, 
        rowsPerPage : 6, 
        search: null,
        where:" r.status = 8 AND r.quotation_status = 2 ",
        sort: 'new',
        from: null,
        to: null
      }
      await axios.post(`${REST_API}webservice/request/get-all-quotations-count`, values)
        .then((res) => {
          if (res?.data?.data?.totalCount) {
            setEstimateCount(res?.data?.data?.totalCount)
            localStorage.setItem('estimateCount', res?.data?.data?.totalCount)
          } else {
            setEstimateCount(0)
            localStorage.setItem('estimateCount', 0)
          }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
          setEstimateCount(0)
          localStorage.setItem('estimateCount', 0)
        })
    } catch (error) {
      console.log(error)
      setEstimateCount(0)
      localStorage.setItem('estimateCount', 0)
    }
  }
  const fetchDesignOrders = async () => {
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      let userType = user.user_type
      let userId = user.user_id

      const values = {
        userType: userType,
        userId: userId,
        page: null,
        rowsPerPage:null,
        search: null,
        from: null,
        to: null,
      }
      await axios
        .post(`${REST_API}webservice/design/all-design-order-count`, values)
        .then((res) => {
          
          if (res?.data?.data?.openCount) {
            let designCount = res?.data?.data?.openCount
            setDesign(designCount)
            localStorage.setItem('designCount', designCount)
        } else {
          setDesign(0)
          localStorage.setItem('designCount', 0)
        }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
          setDesign(0)
          localStorage.setItem('designCount', 0)
        })
    } catch (error) {
      console.log(error)
      setDesign(0)
      localStorage.setItem('designCount', 0)
    }
  }

  const fetchAllRequest = async () => {
    try {
      let user = JSON.parse(localStorage.getItem('user'))
      let userType = user.user_type
      let userId = user.user_id

      let whereCondition = ` ((r.status=2) OR (r.status=3 AND r.min_payment_status=0)) `
      const values = {
        userType: userType, userId: userId, 
        page: 1, rowsPerPage:6, search: '', where: whereCondition,
        sort:null,
        from:null,
        to:null,
        filterCommunity:null
      }
      await axios.post(`${REST_API}webservice/request/get-all-request-new`, values)
        .then((res) => {
          setReqCount(res?.data?.data?.totalCount || 0)
          localStorage.setItem('requestCount', res?.data?.data?.totalCount || 0);
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
          setReqCount(0)
        })
    }catch(error){
      console.log("fetchAllRequest error : ",error)
      setReqCount(0)
    }
  }
const fetchInstallations=async()=>{
  try{
    let user = JSON.parse(localStorage.getItem('user'))
    let userType = user.user_type
    let userId = user.user_id
    const response = await axios.post(`${REST_API_END_POINT}installation/fetch-all-appointments-count`,{ 
      userType: { value: userType}, 
      userId: { value: userId},
      hasPermission:true,
      sort:null,
      tab: 'Awaiting',
      from:null,
      to:null,
      dueData:null,
      search:null,
      project_id : null, },
      // { cancelToken: cancelTokenRef.current.token },
      {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if(response.data.status===1){
      let installsCount=response.data.installsAwaitingCount
      let pickupCount=response.data.pickupAwaitingCount
      let shippingCount=response.data.shippingAwaitingCount
      setInstalls(installsCount)
      localStorage.setItem('installsCount', installsCount);
      setPickup(pickupCount)
      localStorage.setItem('pickupCount', pickupCount);
      setShipping(shippingCount)
      localStorage.setItem('shippingCount', shippingCount);
    }else{
      setInstalls(0)
      setPickup(0)
      setShipping(0)
      localStorage.setItem('installsCount', 0);
      localStorage.setItem('pickupCount', 0);
      localStorage.setItem('shippingCount', 0);
    }
  }catch(error){
    console.log("fetchAllRequest error : ",error)
    setInstalls(0)
    setPickup(0)
    setShipping(0)
    localStorage.setItem('installsCount', 0);
    localStorage.setItem('pickupCount', 0);
    localStorage.setItem('shippingCount', 0);
  }
}
  // useEffect( async () => {
  //   if(userData.user_type == 3){
  //     try {
  //       const response = await axios.post(`${REST_API_END_POINT}product/get-all-receive-orders`,
  //         {userId: userData.user_id, userType: userData.user_type });
  //       if(response.data.status === 1){
  //         const orderList = response?.data?.orderData;
  //         setReceiveOrderCount(orderList?.length)
  //       }
  //       else{
  //         setReceiveOrderCount(0);
  //       }
  //     }catch (error) {
  //       console.error("Error fetching data:", error);
  //       setReceiveOrderCount(0);
  //     }
  //   }
  // }, []);

  const fetchRFPData = async () => {
    try {
      const values = {
        userType: userData.user_type, userId: userData.user_id, 
        page: 1, rowsPerPage:6, search: null,
        sort:'new',
        from:null,
        to:null
      }
      await axios.post(`${REST_API}webservice/request/get-all-proposals`, values)
        .then((res) => {
          if (res?.data?.data?.totalCount) {
            setRFPCount(res?.data?.data?.totalCount)
          } else {
            setRFPCount(0)
          }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
          setRFPCount(0)
        })
    } catch (error) {
      console.log(error)
      setRFPCount(0)
    }
  }

  const fetchCompletedInstallationsDataForDashboard = async () => {
    try {
      let whereCondition = ` i.status IS NOT NULL AND ( inst_counts.installation_count >= cop.product_count OR inst_counts.installation_count >= rss.service_count ) `
      const values = {
        userType: userData.user_type, userId: userData.user_id, page: 1, 
        rowsPerPage:6, search: null, 
        where: whereCondition,
        sort:'new',
        from:null,
        to:null,
        filterCommunity:[]
      }
      await axios.post(`${REST_API}webservice/request/get-all-request-new`, values)
        .then((res) => {
          if (res?.data?.data?.totalCount) {
            setCompletedInstallationCount(res?.data?.data?.totalCount)
          } else {
            setCompletedInstallationCount(0)
          }
        })
        .catch((errors) => {
          console.log(errors, 'error in fetching all requests')
          setCompletedInstallationCount(0)
        })
    } catch (error) {
      console.log(error)
      setCompletedInstallationCount(0)
    }
  }

  // useEffect(() => {
  //   if(userData.user_type == 3){
  //     fetchDashboardData()
  //     fetchDashboardEstimateData()
  //     // fetchCompletedInstallationsDataForDashboard()
  //     // fetchRFPData()
  //   }
  // }, [userData.user_type])

      useEffect(() => {
    if(userData.user_type == 3){
      // fetchAllRequest();
      fetchDashboardData()
    }
    // else{
    //   fetchDashboardData()
    // }
    if(userData.user_type != 1){
      fetchDashboardEstimateData()
      fetchInstallations()
      fetchDesignOrders()
      // fetchCompletedInstallationsDataForDashboard()
      // fetchRFPData()
    }
  }, [userData.user_type,added])



  const fetchPermissions = async () => {
    try {
      const response = await axios.post(`${REST_API}get-sidebar-permissions`,{userId:userData.user_id});
      if(response.data.status==1){
        const permissionData = response.data.permissionData;
        const orderData = response.data.orderData;
        console.log('orderData====',orderData)
        setOrder(orderData);
        localStorage.setItem('sidebar_order', JSON.stringify(orderData));
        localStorage.setItem('permission_data',JSON.stringify(permissionData));
        if (permissionData) {
          const sortedItems = permissionData.sort((a, b) => {
            return orderData.indexOf(a.id) - orderData.indexOf(b.id);
          });
          setPermissionsData(sortedItems)
        } else {
          setPermissionsData([]);
        }
      } else {
        setPermissionsData([]);
      }
    }
    catch (error) {
      console.error("Error fetching data:", error);
      setPermissionsData([]);
    }
  }

  useEffect(()=>{
    const savedOrderData = JSON.parse(localStorage.getItem('sidebar_order'));
    const permissionData = JSON.parse(localStorage.getItem('permission_data'));
    if(savedOrderData && savedOrderData?.length > 0){
      setOrder(savedOrderData);
      if (permissionData) {
        const sortedItems = permissionData.sort((a, b) => {
          return savedOrderData.indexOf(a.id) - savedOrderData.indexOf(b.id);
        });
        setPermissionsData(sortedItems)
      } else {
        setPermissionsData([]);
      }
    }else{
      fetchPermissions();
    }
  },[]);

  const saveOrderToBackend = async (updatedOrder) => {
    const response = await axios.post(`${REST_API}update-sidebar-order`,{
      userId:userData.user_id,
      newOrder: updatedOrder?.join(',')
    });
    // console.log('saveOrderToBackend response : ',response.data);
    // console.log('saveOrderToBackend newOrder : ',updatedOrder?.join(','));
  };

  // ----------------------------------------------------------------------

  const userType = userData.user_type;
  const explodedPermissions = userData.exploded_permissions;
  const Title = userData.job_title
  const { workSpaceTitle } = useWorkSpaceOption()
  let JOB_TITLE= Title ? Title :'Jobs'


  const checkPermissions = (permissionChecks, items) => {
    // console.log('permissionChecks--------',permissionChecks)
    // console.log('Exploded Permissions:', explodedPermissions);
    return permissionChecks.map(({ permissions, title,name }) => {
      const hasAnyPermission = permissions.some((perm) =>
        explodedPermissions.includes(perm)
      );
      // console.log(`Checking ${name} 1 :`, hasAnyPermission);
      // console.log(`Checking ${name} 2 :`, items);

      if (hasAnyPermission) {
        return items
          .filter((item) => name.includes(item.name))
          // .filter((item) => item.name === name)
          .map((item) => (
            <NavItem requestCount={requestCount} installsCount={installsCount} pickupCount={pickupCount} shippingCount={shippingCount} estimateCount={estimateCount} designCount={designCount}
              key={item.title}  isCompact={isCompact} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
          ));
      }
      return null;
    });
  };

  const handleDragEnd = async (result) => {
    const { source, destination } = result;
  
    if (!destination) return;
  
    const sectionIndex = parseInt(source.droppableId.split('-')[1], 10);
    const sectionItems = [...navConfig[sectionIndex].items];
  
    navConfig[sectionIndex].items = sectionItems;

    const filteredItems = filterItemsByUserType(sectionItems);
    const updatedFilteredItems = [...filteredItems];
    const [movedItem] = updatedFilteredItems.splice(source.index, 1);

    updatedFilteredItems.splice(destination.index, 0, movedItem);

    const reorderedOrder = updatedFilteredItems.map(item => {
      const permission = permissionsData.find(p => p.name === item.name);
      return permission ? permission.id : null;
    }).filter(id => id !== null);

    // console.log('Reordered permission IDs:', reorderedOrder);
    setOrder(reorderedOrder);
    localStorage.setItem('sidebar_order', JSON.stringify(reorderedOrder));
    localStorage.setItem('permission_data',JSON.stringify(permissionsData));
    await saveOrderToBackend(reorderedOrder);
  };  

  const reorderFilteredItems = (orderData, permissionData, filteredItemsData, items) => {

    const filteredItems = filteredItemsData?.map(item => {
      // Check if the item is a valid React element and extract item.props.item safely
      if (item && item[0] && item[0].props && item[0].props.item) {
        return item[0].props.item; // Extract item from the first element of the array
      }
      return null; // Return null if no valid item found
    }).filter(item => item !== null); // Filter out any null values
    
    
    // Step 1: Create a map for quick lookup of permission objects by their IDs
    const permissionMap = permissionData.reduce((map, permission) => {
      map[permission.id] = permission;
      return map;
    }, {});
  
    const itemPermissionMap = filteredItems?.reduce((map, item) => {
      const permission = permissionData.find(p => p.name === item.name);
      if (permission) {
        map[item.name] = permission.id;
      }
      return map;
    }, {});
  
    const itemPCB = items.filter(item => item.title=='Production control board');
    let clientItems = [];
    if (userType === 3) {
      clientItems = [
        ...items.filter(item => item.name === 'OnboardingClient' && userData.isVendor !== 1),
        ...items.filter(item => item.title === 'Estimate' && userData.isVendor !== 1),
        ...items.filter(item => item.title === 'design order' && userData.isVendor !== 1),
        ...items.filter(item => item.title === 'Sales Order' && userData.isVendor !== 1),
        ...items.filter(item => item.title === 'Purchase Order' && userData.isVendor === 1),
        ...items.filter(item => item.title === 'Projects' && userData.isVendor !== 1),
        ...items.filter(item => item.name === 'job_client' && userData.isVendor !== 1),
        ...items.filter(item => item.name === 'recieve_order_vendor' && userData.isVendor === 1),
      ];
    }
    let driverItems = [];
    if (userType === 24) {
      driverItems = [
        ...items.filter(item => item.name === 'ProfileDrivers'),
      ];
    }
    const reorderedMatchingItems  = orderData
      .map(id => {
        const itemTitle = Object.keys(itemPermissionMap).find(key => itemPermissionMap[key] === id);
        return filteredItems.find(item => item.name === itemTitle);
      })
      .filter(item => item !== undefined);

    const reorderedItems = userType === 3 
      ? [...itemPCB, ...clientItems]
      : [...itemPCB, ...driverItems, ...reorderedMatchingItems];

    return reorderedItems;
  };

  const filterItemsByUserType = (items) => {
    // const filteredItems = items.filter((item) => {
      // if (item.title === 'Production control board') return true;
      // // Add userType-specific checks here
      // if (userType === 3) {
      //   if (item.name === 'OnboardingClient') return true;
      //   if (item.title === 'Quick Bid' && userData.isVendor !== 1) return true;
      //   if (item.title === 'design order' && userData.isVendor !== 1) return true;
      //   if (item.title === 'official request' && userData.isVendor !== 1) return true;
      //   if (item.title === 'RFP' && userData.isVendor === 1) return true;
      //   if (item.title === 'Projects' && userData.isVendor !== 1) return true;
      //   if (item.name === 'job_client' && userData.isVendor !== 1) return true;
      //   if (item.name === 'recieve_order_vendor' && userData.isVendor === 1) return true;
      // } 
      // if (userType !== 3) {
        const filteredItems = checkPermissions(permissionsData, items);
        // const permissionMatched = hasPermission && hasPermission?.some(permission => {
        //   // Check if the permission is valid or matches your condition
        //   return explodedPermissions.includes(permission);  // or any other condition for valid permissions
        // });
        // console.log('newFiltered=3====',permissionMatched)
        // return hasPermission;
      // }
      // return false;
    // });
    // console.log('filteredItems---',filteredItems)
    const reorderedItems = reorderFilteredItems(order, permissionsData, filteredItems, items);   
    return reorderedItems;
  };

  
  return (
    <Box {...other}>
      <DragDropContext onDragEnd={handleDragEnd}>
        {navConfig?.map((list, sectionIndex) => {
          const { subheader, items } = list;

          // Filter items based on userType and permissions
          const filteredItems = filterItemsByUserType(items);
          return (
            <Droppable key={subheader} droppableId={`section-${sectionIndex}`} type="NAV">
              {(provided) => (
                <List ref={provided.innerRef} {...provided.droppableProps} disablePadding>
                  {filteredItems?.map((item, index) => (
                    <Draggable key={item.title} draggableId={`item-${item.title}`} index={index} isDragDisabled={item.title === 'Production control board' || userType==3}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <NavItem
                            requestCount={requestCount} installsCount={installsCount} pickupCount={pickupCount} shippingCount={shippingCount} estimateCount={estimateCount} designCount={designCount}
                            isCompact={isCompact}
                            key={item.title}
                            item={item}
                            active={match}
                            explodedPermissions={explodedPermissions}
                            userType={userType}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          );
        })}
      </DragDropContext>
    </Box>
  );
}   

// import { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { Icon } from '@iconify/react';
// import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
// import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// // material
// import { alpha, useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
// import { Box, List, ListItem, Collapse, ListItemText, ListItemIcon, ListSubheader } from '@material-ui/core';
// import { PATH_DESKTOP } from 'src/routes/paths';
// import axios from 'axios';
// import { REST_API_END_POINT } from 'src/constants/Defaultvalues';
// import useWorkSpaceOption from 'src/hooks/useWorkspaceOptions';

// // ----------------------------------------------------------------------

// const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
//   ({ theme }) => ({
//     ...theme.typography.overline,
//     marginTop: theme.spacing(3),
//     marginBottom: theme.spacing(2),
//     paddingLeft: theme.spacing(5),
//     color: theme.palette.text.primary
//   })
// );

// const ListItemStyle = styled((props) => <ListItem button disableGutters {...props} />)(({ theme }) => ({
//   ...theme.typography.body2,
//   height: 48,
//   position: 'relative',
//   textTransform: 'capitalize',
//   paddingLeft: theme.spacing(4),
//   paddingRight: theme.spacing(2.5),
//   // color: theme.palette.text.secondary,  temporary change
//   '&:before': {
//     top: 0,
//     right: 0,
//     width: 3,
//     bottom: 0,
//     content: "''",
//     display: 'none',
//     position: 'absolute',
//     borderTopLeftRadius: 4,
//     borderBottomLeftRadius: 4,
//     // backgroundColor: isActiveSub ? theme.palette.primary.main : color || theme.palette.success.main,   temporary change
//   }
// }));

// const ListItemIconStyle = styled(ListItemIcon)({
//   width: 22,
//   height: 22,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center'
// });

// // ----------------------------------------------------------------------

// NavItem.propTypes = {
//   item: PropTypes.object,
//   active: PropTypes.func
// };

// function NavItem({ item, active, explodedPermissions, userType,isCompact }) {
//   const theme = useTheme();
//   const userData = JSON.parse(localStorage.getItem('user'))
//   const Title = userData.job_title
//   const { workSpaceTitle } = useWorkSpaceOption()
//   let JOB_TITLE= Title ? Title :'Jobs'
//   const isActiveRoot = active(item.path);
//   const { title, path, icon, info, children,color } = item;
//   const [open, setOpen] = useState(isCompact && isActiveRoot);

//   const handleOpen = () => {
//     setOpen((prev) => !prev);
//   };

//   const handleListItemClick = (path,event) => {
//     if (path.endsWith('/incoming-window')) {
//       window.open(PATH_DESKTOP.root, '_blank','width=1000,height=1000');
//       event.preventDefault();
//     }
//   };

//   const checkChildPermissions = (permissionChecks, items) => {
//     return permissionChecks.map(({ permissions, title }) => {
//       const hasAnyPermission = permissions.some((perm) =>
//         explodedPermissions.includes(perm)
//       );

//       if (hasAnyPermission) {
//         const filteredItems = items.filter((item) => title === item.title)
//         if (filteredItems.length > 0) {
//           return filteredItems.map((item) => {
//             const { title, path } = item;
//             const isActiveSub = active(path);
//             return (
//               <ListItemStyle
//                 key={title}
//                 component={RouterLink}
//                 onClick={(e)=>handleListItemClick(path,e)}
//                 to={path}
//                 sx={{
//                   ...(isActiveSub && activeSubStyle)
//                 }}
//               >
//                 <ListItemIconStyle>
//                   <Box
//                     component="span"
//                     sx={{                     
//                       width: 4,
//                       height: 4,
//                       display: 'flex',
//                       borderRadius: '50%',
//                       alignItems: 'center',
//                       justifyContent: 'center',
//                       bgcolor: 'text.disabled',
//                       transition: (theme) => theme.transitions.create('transform'),
//                       ...(isActiveSub && {
//                         transform: 'scale(2)',
//                         bgcolor: 'primary.main'
//                       })
//                     }}
//                   />
//                 </ListItemIconStyle>
//                 {/* <ListItemText disableTypography primary={title} /> */}
//                 {!isCompact && <ListItemText disableTypography primary={title} />}
//               </ListItemStyle>
//             )
//           })
//         };
//       }
//       return null;
//     });
//   };
//   const activeRootStyle = {
//     color: 'primary.main',
//     fontWeight: 'fontWeightMedium',
//     bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
//     '&:before': { display: 'block' }
//   };

//   const activeSubStyle = {
//     color: 'text.primary',
//     fontWeight: 'fontWeightMedium'
//   };

//   if (children) {
//     return (
//       <>
//         <ListItemStyle
//           onClick={handleOpen}
//           sx={{
//             ...(isActiveRoot ? activeRootStyle : color==='success' ? {bgcolor:theme.palette.success.main} : {})  //temporary
//           }}
//         >
//           <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
//          {!isCompact && <ListItemText disableTypography primary={title} />}
//           {info && info}
//           <Box
//             component={Icon}
//             icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
//             sx={{ width: 16, height: 16, ml: 1 }}
//           />
//         </ListItemStyle>

//         <Collapse in={open} timeout="auto" unmountOnExit>
//           <List component="div" disablePadding>
//             {userType !== 3 && (
//               checkChildPermissions([
//                 {
//                   permissions: ['View Contacts','Add Contact', 'Activate/Deactivate Contact', 'Delete Contact', 'Edit Contact', 'View Contact'],
//                   title: 'Contacts',
//                 },
//                 {
//                   permissions: ['View All Leads','Add Lead', 'Activate/Deactivate Lead', 'Delete Lead', 'View Lead', 'Edit Lead','View Lead Statuses','Add Lead Status','Edit Lead Status','Activate/Deactivate Lead Status','Delete Lead Status'],
//                   title: 'Leads',
//                 },
//                 {
//                   permissions: ['View Opportunities','Add Opportunity', 'Activate/Deactivate Opportunity', 'Delete Opportunity', 'View Opportunity', 'Edit Opportunity'],
//                   title: 'Opportunities',
//                 },
//                 {
//                   permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
//                   title: 'Workflow',
//                 },
//                 {
//                   permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
//                   title: 'Production Workflow',
//                 },
//                 {
//                   permissions: ['View Onboarding Workflows','Create Onboarding Workflows','Edit Onboarding Workflows', 'Delete Onboarding workflows','Activate/Deactivate Onboarding Workflows'],
//                   title: 'Service Workflow',
//                 },
//                 {
//                   permissions: ['View Custom Field Template','Create Custom Field Template', 'Activate/Deactivate Custom Field Template','Delete Custom Field Template'],
//                   title: 'Custom Field Template',
//                 },
//                 {
//                   permissions: ['View Questionnaire Template','Create Questionnaire Template','Activate/Deactivate Questionnaire Template','Delete Questionnaire Template'],
//                   title: 'Questionnaire Templates',
//                 },
//                 {
//                   permissions: ['View Sign Agreements','Create Sign Agreements', 'Edit Sign Agreements','Activate/Deactivate Sign Agreements','Delete Sign Agreements'],
//                   title: 'Sign Agreements',
//                 },
//                 {
//                   permissions: ['View Departments','Add Department','Activate/Deactivate Department','Delete Department','View Department','Edit Department'],
//                   title: 'Departments',
//                 },
//                 {
//                   permissions: ['View team members','Inviting team members','Add team members','Edit team members','Delete team members'],
//                   title: 'Team Members',
//                 },
//                 {
//                   permissions: ['View All User Roles','Add User Roles','Edit User Roles','Activate/Deactivate User Roles','Delete User Roles'],
//                   title: 'User Roles',
//                 },
//                 {
//                   permissions: ['Add New Installation','View All Installations'],
//                   title: 'Installation',
//                 },
//                 {
//                   permissions: ['View Installation Templates','Add Installation Templates','Delete Installation Templates'],
//                   title: 'Installation Template',
//                 },             
//                 {
//                   permissions: ['Time Tracking'],
//                   title: 'Time Tracking',
//                 },
//                 {
//                   permissions: ['Add Staff Payout','Edit Staff Payout','View All Staff Payout','Payout based on Time-Tracking'],
//                   title: 'Staff Payout',
//                 },
//                 {
//                   permissions: ['View All Staff Schedules','Add Staff Schedule'],
//                   title: 'Staff Scheduler',
//                 },
//                 {
//                   permissions: ['Add Email', 'Deactivate Email', 'Delete Email'],
//                   title: 'Emails',
//                 },
//                 {
//                   permissions: ['Add Calendar', 'Deactivate Calendar', 'Delete Calendar'],
//                   title: 'Calendar',
//                 },
//                 {
//                   permissions: ['Add Meeting', 'Deactivate Meeting', 'Delete Meeting',],
//                   title: 'Meetings',
//                 },
//                 {
//                   permissions: ['Call Logs'],
//                   title: 'Calls',
//                 },
//                 {
//                   permissions: ['SMS Logs'],
//                   title: 'SMS',
//                 },
//                 {
//                   permissions: ['View Ticket','Add Ticket', 'Delete Ticket', 'Edit Ticket'],
//                   title: 'Tickets',
//                 },
//                 {
//                   permissions: ['View Campaigns','Add Campaign', 'Activate/Deactivate Campaign', 'Delete Campaign', 'View Campaign', 'Edit Campaign','View Custom Contact Form','Add Custom Contact Form','Delete Custom Contact Form','View Customization form','View/Export Enquiries'],
//                   title: 'Campaigns',
//                 },
//                 {
//                   permissions: ['View Jobs','Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','Manage Job Cost'],
//                   title: `All Jobs`,
//                 },
//                 {
//                   permissions: ['View Job Types','Add Job Types','Edit Job Types','Activate/Deactivate Job Types','Delete Job Types'],
//                   title: `Jobs Type`,
//                 },
//                 {
//                   permissions: ['View Job Categories','Add Job Categories','Edit Job Categories','Activate/Deactivate Job Categories','Delete Job Categories'],
//                   title: `Jobs Category`,
//                 },
//                 {
//                   permissions: ['Calls'],
//                   title: 'incoming window',
//                 },
//                 {
//                   permissions: ['Call Analytics'],
//                   title: 'analytics',
//                 },
//                 {
//                   permissions: ['Manage Call flow','View team members','Inviting team members','Add team members','Edit team members', 'Delete team members','View Departments', 'Add Department', 'Activate/Deactivate Department', 'Delete Department', 'Edit Department'],
//                   title: 'account setup',
//                 },
//                 {
//                   permissions: ['Call Logs'],
//                   title: 'call logs',
//                 },
//                 {
//                   permissions: ['SMS Logs'],
//                   title: 'sms logs',
//                 },
//                 {
//                   permissions: ['Access Call Recordings'],
//                   title: 'recordings',
//                 },
//                 // {
//                 //   permissions: ['Monitor Call'],
//                 //   title: 'monitor calls',
//                 // },
//                 // {
//                 //   permissions: ['Inviting clients', 'Assigning relationship managers'],
//                 //   title: 'all clients',
//                 // },
//                 {
//                   permissions: ['View Catalog','View Catalogs','Add Catalog','Edit Catalog','Activate/Deactivate Catalog','Delete Catalog' ],
//                   title: 'Catalog',
//                 },
//                 {
//                   permissions: ['View all services','Add services','Edit services','Activate/Deactivate Services','Delete Services' ],
//                   title: 'Services',
//                 },
//                 {
//                   permissions: ['View Product Formulas','Add Product Formula','Edit Product Formula','Delete Product Formula'],
//                   title: 'Product Formula',
//                 },
//                 {
//                   permissions: ['View Materials','View Material Label','Add Material Label','Edit Material Label','Activate/Deactivate Material Label','Delete Material Label','View Finished Product','Add Finished Product'],

//                   title: 'Material Inventory',
//                 },
//                 {
//                   permissions: ['Inventory Audit','Edit Inventory','View Inventory Audit Log'],
//                   title: 'Inventory Audit',
//                 },
//                 {
//                   permissions: ['View Categories','Add category','Edit category','Activate/Deactivate category','Delete category'],
//                   title: 'Category',
//                 },
//                 {
//                   permissions: ['View Sub Categories','Add Sub category','Edit Sub category','Activate/Deactivate Sub category','Delete Sub category'],
//                   title: 'Sub Category',
//                 },
//                 {
//                   permissions: ['Transportation'],
//                   title: 'Transportation',
//                 },
//                 {
//                   permissions: ['Manage Routes'],
//                   title: 'Routes',
//                 },
//                 {
//                   permissions: ['Route Optimization'],
//                   title: 'Route Optimization',
//                 },
//                 {
//                   permissions: ['Manage Drivers'],
//                   title: 'Drivers',
//                 },
//                 {
//                   permissions: ['Manage Vehicles'],
//                   title: 'Vehicles',
//                 },
//                 {
//                   permissions: ['Track drivers'],
//                   title: 'Track Drivers',
//                 },
//                 {
//                   permissions: ['View Vendors','Add Vendors','Edit Vendors','Delete Vendors'],
//                   title: 'All Vendors',
//                 },
//                 // {
//                 //   permissions: ['View Tax category'],
//                 //   title: 'Tax categories',
//                 // },
//                 {
//                   permissions: ['View All Orders','Create Order','Edit Order','Cancel Order','View Order','View Invoice','Make payment','Receive Order'],
//                   title: 'Receive Inventory Orders',
//                 },
//               ], children)
//             )}

//             {userType == 1 && (
//               checkChildPermissions([
//                 {
//                   permissions: ['Call Settings'],
//                   title: 'call settings',
//                 },
//               ], children))}

//             {/* {children.map((item) => {
//               const { title, path } = item;
//               const isActiveSub = active(path);

//               return (
//                 <ListItemStyle
//                   key={title}
//                   component={RouterLink}
//                   to={path}
//                   sx={{
//                     ...(isActiveSub && activeSubStyle)
//                   }}
//                 >
//                   <ListItemIconStyle>
//                     <Box
//                       component="span"
//                       sx={{
//                         width: 4,
//                         height: 4,
//                         display: 'flex',
//                         borderRadius: '50%',
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         bgcolor: 'text.disabled',
//                         transition: (theme) => theme.transitions.create('transform'),
//                         ...(isActiveSub && {
//                           transform: 'scale(2)',
//                           bgcolor: 'primary.main'
//                         })
//                       }}
//                     />
//                   </ListItemIconStyle>
//                   <ListItemText disableTypography primary={title} />
//                 </ListItemStyle>
//               );
//             })} */}
//           </List>
//         </Collapse>
//       </>
//     );
//   }

//   return (
//     <ListItemStyle
//       component={RouterLink}
//       to={path}
//       sx={{
//         ...(isActiveRoot ? activeRootStyle : color==='success' ? {bgcolor:theme.palette.success.main} : {}) //temporary
//       }}
//     >
//       <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
//     { !isCompact &&<ListItemText disableTypography primary={title} /> }
//       {info && info}
//     </ListItemStyle>
//   );
// }

// NavSection.propTypes = {
//   navConfig: PropTypes.array
// };

// export default function NavSection({ navConfig,isCompact, ...other }) {

//   const { pathname } = useLocation();
//   const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

//   // ----------------------------------------------------------------------
//   const user = localStorage.getItem('user')
//   const userData = JSON.parse(user);
//   console.log('userData------',userData);
//   const userType = userData.user_type;
//   const explodedPermissions = userData.exploded_permissions;
//   const Title = userData.job_title
//   const { workSpaceTitle } = useWorkSpaceOption()
//   let JOB_TITLE= Title ? Title :'Jobs'


//   const checkPermissions = (permissionChecks, items) => {
//     return permissionChecks.map(({ permissions, title,name }) => {
//       const hasAnyPermission = permissions.some((perm) =>
//         explodedPermissions.includes(perm)
//       );

//       if (hasAnyPermission) {
//         return items
//           .filter((item) => name.includes(item.name))
//           .map((item) => (
//             <NavItem key={item.title}  isCompact={isCompact} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//           ));
//       }
//       return null;
//     });
//   };


//   return (
//     <Box {...other}>
//       {navConfig.map((list) => {
//         const { subheader, items } = list;
//         return (
//           <List key={subheader} disablePadding>
//             {/* <ListSubheaderStyle>{subheader}</ListSubheaderStyle> */}
//             {/* --------------------------------------------------------------------------------------------------------- */}
//             {/* {userType == 1 && items.map((item) => (
//               <NavItem key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType}/>
//             ))
//             } */}
//             {/* {userType == 3 &&
//               items
//                 .filter(item => item.type == userType)
//                 .map(item => (
//                   <NavItem key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType}/>
//                 ))} */}
//             {/* --------------------------------------------------------------------------------------------------------- */}
//             {
//               items
//                 .filter(item => item.title == 'Production control board')
//                 .map(item => (
//                   <NavItem isCompact={isCompact} key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 ))
//             }
            
//             {(userType == 3) &&
//               items
//                 .filter(item => item.name == 'OnboardingClient')
//                 .map(item => (
//                   <NavItem isCompact={isCompact} key={item.name} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 ))
//             }
//              {(userType == 3) &&userData.isVendor!=1&&
//               items
//                 .filter(item => item.title == 'Quick Bid')
//                 .map(item => {
//                   let newItem = { ...item,title:'Quick Bid'}
//                   return <NavItem isCompact={isCompact} key={item.title} item={newItem} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 })
//             }
//             {(userType == 3 && userData.isVendor!=1) &&
//               items
//                 .filter(item => item.title == 'design order')
//                 .map(item => {
//                   let newItem = { ...item,title: 'design order'}
//                   return <NavItem isCompact={isCompact} key={item.title} item={newItem} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 })
//             }
//             {(userType == 3 && userData.isVendor!=1) &&
//               items
//                 .filter(item => item.title == 'official request')
//                 .map(item => {
//                   let newItem = { ...item,title: 'official request'}
//                   return <NavItem isCompact={isCompact} key={item.title} item={newItem} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 })
//             }
//            {(userType == 3 && userData.isVendor===1) &&
//               items
//                 .filter(item => item.title == 'RFP')
//                 .map(item => {
//                   let newItem = { ...item,title: 'RFP'}
//                   return <NavItem isCompact={isCompact} key={item.title} item={newItem} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 })
//             }
//             {(userType == 3 && userData.isVendor !== 1) &&
//               items
//                 .filter(item => item.title == 'Projects')
//                 .map(item => (
//                   <NavItem isCompact={isCompact} key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 ))
//             }
//             {(userType == 3 && userData.isVendor !== 1) &&
//               items
//                 .filter(item => item.name === "job_client")
//                 .map(item => (
//                   <NavItem isCompact={isCompact} key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 ))
//             }
//             {(userType == 3 && userData.isVendor == 1) &&
//               items
//                 .filter(item => item.name === "recieve_order_vendor")
//                 .map(item => (
//                   <NavItem isCompact={isCompact} key={item.title} item={item} active={match} explodedPermissions={explodedPermissions} userType={userType} />
//                 ))
//             }
//             {/* --------------------------------------------------------------------------------------------------------- */}
//             {userType !== 3 &&
//               checkPermissions([
//                 {
//                   permissions: ['Manage Installation Calendar','View Installation Calendar','View as admin'],
//                   name: 'Installation Calendar',
//                 },
//                 {
//                   permissions: ['Calls','Monitor Call','Access Call Recordings','Call Logs','Call Analytics','Manage Call flow','Call Settings','SMS Logs','View team members','Inviting team members','Add team members','Edit team members', 'Delete team members', 'View Departments','Add Department', 'Activate/Deactivate Department', 'Delete Department', 'Edit Department'],
//                   name: 'call center',
//                 },
//                 {
//                   permissions: ['View request','Create Request', 'Edit Request', 'Delete request','Add vendor to request','Add client to request','View Markup'],
//                   name: 'RFP',
//                 },
//                 {
//                   permissions: ['View request','Create Request', 'Edit Request', 'Delete request','Add vendor to request','Add client to request','View Markup'],
//                   name: 'Quick Bid',
//                 },
//                 {
//                   permissions: ['View request','Create Request', 'Edit Request', 'Delete request','Add vendor to request','Add client to request','View Markup'],
//                   name: 'design order',
//                 },
//                 {
//                   permissions: ['View request','Create Request', 'Edit Request', 'Delete request','Add vendor to request','Add client to request','View Markup'],
//                   name: 'official request',
//                 },
                
//                 {
//                   permissions: ['View Projects','Add Projects','Edit Projects','Activate/Deactivate Projects','Delete Projects','Manage Project Types'],
//                   name: 'Projects',
//                 },
//                 {
//                   permissions: ['View Tax category'],
//                   name: 'Tax categories',
//                 },
//                 {
//                   permissions: ['View Jobs','Add Jobs','Edit Jobs','Activate/Deactivate Jobs','Delete Jobs','View Job Types','Add Job Types','Edit Job Types','Activate/Deactivate Job Types','Delete Job Types','View Job Categories','Add Job Categories','Edit Job Categories','Activate/Deactivate Job Categories','Delete Job Categories','Manage Job Cost'],
//                   name: "Jobs",
//                 },
//                 {
//                   permissions: ['Add New Installation','View All Installations','View Installation Templates','Add Installation Templates','Delete Installation Templates'],
//                   name: 'installation',
//                 },
//                 {
//                   permissions: ['View all Vendors','View Vendors','Add Vendors','Edit Vendors','Delete Vendors','View Tax category','View All Orders','Create Order','Edit Order','Cancel Order','View Order','View Invoice','Make payment','Receive Order'],
//                   name: 'purchase',
//                 },
//                 {
//                   permissions: ['View Onboarding Workflows','Create Onboarding Workflows', 'Edit Onboarding Workflows','Activate/Deactivate Onboarding Workflows', 'Delete Onboarding workflows','View Custom Field Template','Create Custom Field Template','Activate/Deactivate Custom Field Template','Delete Custom Field Template','View Questionnaire Template','Create Questionnaire Template','Activate/Deactivate Questionnaire Template','Delete Questionnaire Template','View Sign Agreements','Create Sign Agreements','Edit Sign Agreements','Activate/Deactivate Sign Agreements','Delete Sign Agreements'],
//                   // title: 'Onboarding',
//                   name: 'Onboarding',
//                 },
//                 {
//                   permissions: ['View team members','Inviting team members','Add team members','Edit team members','Delete team members','View Departments','Add Department','Activate/Deactivate Department','Delete Department','Edit Department','Add User Roles','Edit User Roles','Activate/Deactivate User Roles','Delete User Roles','Time Tracking','Add Staff Payout','Edit Staff Payout','View All Staff Payout','View All Staff Schedules','Add Staff Schedule','Payout based on Time-Tracking','View All User Roles'],
//                   // title: 'Staff Management',
//                   name: 'Staff Management',
//                 },
//                 {
//                   permissions: ['View Contacts','Add Contact', 'Edit Contact', 'Delete Contact','View Contact','Activate/Deactivate Contact','View All Leads','Add Lead','Edit Lead','View Lead','Activate/Deactivate Lead','Delete Lead','Convert Lead','View Lead Statuses','Add Lead Status','Edit Lead Status','Activate/Deactivate Lead Status','Delete Lead Status','View Opportunities','Add Opportunity','Edit Opportunity','View Opportunity','Activate/Deactivate Opportunity','Delete Opportunity','View Ticket','Add Ticket','Edit Ticket','Delete Ticket','View Campaigns','Add Campaign','Activate/Deactivate Campaign','Delete Campaign','View Campaign','Edit Campaign','View Custom Contact Form','Add Custom Contact Form','Delete Custom Contact Form','View Customization form','View/Export Enquiries','Call Logs','SMS Logs',],
//                   name: 'CRM',
//                 },
//                 {
//                   permissions: ['Inviting clients', 'Edit clients','Delete clients','View Clients'],
//                   name: 'clients',
//                 },
//                 {
//                   permissions: ['Insights'],
//                   name: 'Insights',
//                 },
//                 {
//                   permissions: ['Inviting clients', 'Assigning relationship managers'],
//                   name: 'Groups',
//                 },
//                 {
//                   permissions: ['Access all payments', 'Mark as paid', 'Add New Payment'],
//                   name: 'Payments',
//                 },
//                 // {
//                 //   permissions: ['Whatsapp'],
//                 //   title: 'Whatsapp',
//                 // },
//                 {
//                   permissions: ['View Catalog','View Catalogs','Add Catalog','Edit Catalog','Activate/Deactivate Catalog','Delete Catalog','View Material Label','View Materials','Add Material Label','Edit Material Label','Activate/Deactivate Material Label','Delete Material Label','View Finished Product','Add Finished Product','Inventory Audit','Edit Inventory','View Inventory Audit Log','View Categories','Add category','Edit category','Activate/Deactivate category','Delete category','View Sub Categories','Add Sub category','Edit Sub category','Activate/Deactivate Sub category','Delete Sub category','View Product Formulas','Add Product Formula','Edit Product Formula','Delete Product Formula','View all services','Add services','Edit services','Activate/Deactivate Services','Delete Services'],
//                   name: 'Inventory',
//                 },
//                 {
//                   permissions: ['Transportation','Manage Routes','Route Optimization','Manage Drivers','Manage Vehicles','Track drivers',],
//                   name: 'Trafficwise',
//                 },
//                 {
//                   permissions: ['View Customer Orders'],
//                   name: 'Customer Orders',
//                 },
//               ], items)
//             }

//           </List>
//         );
//       })}
//     </Box>
//   );
// }
