// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// // ----------------------------------------------------------------------

// export default function ScrollToTop() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }


import React, { useEffect, useState } from 'react';
import { Box, Button,Slide, Stack, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { styled } from '@material-ui/styles';


// Styled Button to control hover effect
const HoverButton = styled(Button)(({ theme }) => ({
position: 'relative',
overflow: 'hidden',
transition: 'width 0.3s ease, border-radius 0.3s ease',
width: '30px',
height: '30px',
minWidth: '30px',
borderRadius: '50%',
boxShadow: 'none',
padding: 0,
display: 'flex',
justifyContent: 'center',
alignItems: 'center',


'& .icon': {
position: 'absolute',
transition: 'transform 0.3s ease',
transform: 'translateX(0)',
},


'& .text': {
position: 'absolute',
left: '-100%',
whiteSpace: 'nowrap',
opacity: 0,
transition: 'left 0.3s ease, opacity 0.3s ease',
},


'&:hover': {
width: '140px',
borderRadius: '10px',
},


'&:hover .icon': {
transform: 'translateX(47px)',
},


'&:hover .text': {
left: '15px',
opacity: 1,
},
}));


export default function ScrollToTop() {
const { pathname } = useLocation();
const [showButton, setShowButton] = useState(false);


useEffect(() => {
window.scrollTo(0, 0);
}, [pathname]);


useEffect(() => {
const handleScroll = () => {
if (window.scrollY > 300) {
setShowButton(true);
} else {
setShowButton(false);
}
};


window.addEventListener('scroll', handleScroll);


return () => window.removeEventListener('scroll', handleScroll);
}, []);


const handleScrollToTop = () => {
window.scrollTo({ top: 0, behavior: 'smooth' });
};


return (
<>
<Slide direction="up" in={showButton} mountOnEnter unmountOnExit>
<Box
sx={{
position: 'fixed',
bottom: 25,
right: 30,
zIndex: 1000,
}}
>
<HoverButton onClick={handleScrollToTop} variant="contained" color="primary">
<Stack className="icon" sx={{ color: '#ffffff' }}>
<svg
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 24 24"
width="18"
height="18"
fill="currentColor"
>
<path d="M3 19h18a1.002 1.002 0 0 0 .823-1.569l-9-13c-.373-.539-1.271-.539-1.645 0l-9 13A.999.999 0 0 0 3 19m9-12.243L19.092 17H4.908z" />
</svg>
</Stack>
{/* <Stack className='bx--up-arrow icon' sx={{ color: '#ffffff' }} /> */}
<Typography className="text" variant="button" sx={{ color: 'white' }}>
Back To Top
</Typography>
</HoverButton>
</Box>
</Slide>
</>
);
}