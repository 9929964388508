import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react'
import chevronLeft from '@iconify/icons-eva/chevron-left-fill'
import chevronRight from '@iconify/icons-eva/chevron-right-fill'
// material
import { alpha,useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD, PATH_DOCS, ROOTS_PCB } from '../../routes/paths';
// components
// import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import { DocIcon } from '../../assets';
import { IconButton } from '@material-ui/core'
import { VERSION } from 'src/constants/Defaultvalues';


// ----------------------------------------------------------------------

const DRAWER_WIDTH = 293;
const DRAWER_WIDTH_COMPACT = 80

export const Switcher = styled(IconButton)(({ theme }) => ({
  marginBottom: 2,
  background: theme.palette.primary.main,
  borderRadius: `6px`,
  padding: `0px`,
  height: 40,
  width: 40,
  margin: theme.spacing(2.45),
  boxShadow: `none`, //0px 5px 18px 0px ${theme.palette.primary.light}
  '&:hover': {
    background: '#07b4eb',
    '& .chev-left': {
      '&:hover': {
        transform: 'scale3d(0.5, 0.5, 0.5)',
        animation: ' move-left 1s ease-out infinite',
        '&:nth-of type(1)': {
          opacity: 0,
          animation: 'move-left 1s ease-out 1s infinite',
        },
        '&:nth-of type(2)': {
          opacity: 0,
          animation: 'move-left 1s ease-out 2s infinite',
        },
      },
    },

    '& .chev-right': {
      '&:hover': {
        animation: ' move-right 1s ease-out infinite',
        '&:nth-of type(1)': {
          opacity: 0,
          animation: 'move-right 1s ease-out 1s infinite',
        },
        '&:nth-of type(2)': {
          opacity: 0,
          animation: 'move-right 1s ease-out 2s infinite',
        },
      },
    },
  },
  '& .chev-icon': {
    color: theme.palette.text.primary,
    position: 'absolute',
  },

  '@keyframes move-left': {
    '25%': {
      opacity: 0.6,
    },
    '33% ': {
      transform: `translateX(${'-4px'})`,
    },
    '67%': {
      opacity: 0.6,
      transform: `translateX(${'-5px'})`,
    },
    '100% ': {
      opacity: 0,
      transform: `translateX(${'-6.5px'}) scale3d(0.5, 0.5, 0.5)`,
    },
  },

  '@keyframes move-right': {
    '25%': {
      opacity: 0.6,
    },
    '33% ': {
      transform: `translateX(${'4px'})`,
    },
    '67%': {
      opacity: 0.6,
      transform: `translateX(${'5px'})`,
    },
    '100% ': {
      opacity: 0,
      transform: `translateX(${'6.5px'}) scale3d(0.5, 0.5, 0.5)`,
    },
  },
}))



const DocStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.5),
  borderRadius: theme.shape.borderRadiusMd,
  backgroundColor:
    theme.palette.mode === 'light' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.primary.lighter
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({
   isOpenSidebar,
    onCloseSidebar,
    isCompact,
    setCompact,
   }) {
    const theme = useTheme()
  const { pathname } = useLocation();
  const { user } = useAuth();
  const userData = JSON.parse(localStorage.getItem('user'))

  //styles
  const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      flexShrink: 0,
      width: `${isCompact === true ? DRAWER_WIDTH_COMPACT : DRAWER_WIDTH}px`,
      margin: `${isCompact === true && theme.spacing(2)}`,
    }
  }),{ index: 999 });

  const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: !isCompact && theme.palette.grey[500_12]
  }));
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleCompact=()=> {
    setCompact(!isCompact)
  }
  const renderContent = (
    <Scrollbar
      sx={{ height: '100%', '& .simplebar-content': { height: '100%',width:'270px', display: 'flex', flexDirection: 'column' } }}
    >
      {/* <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box> */}

      <Box sx={{ my: 3, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to={ROOTS_PCB}>
          <AccountStyle>
            <MyAvatar />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {userData.first_name
                  ?
                  (userData.first_name + ' ' + userData.last_name)
                  :
                  userData.company_name
                }
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {userData.isVendor ? 'Vendor' : userData?.user_type == 3 ? 'Client' : userData.user_role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} sx={{ pb: 2 }} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <DocStyle>
          <DocIcon sx={{ width: 36, height: 36, mb: 2 }} />
          <Typography gutterBottom variant="subtitle1" sx={{ color: 'grey.800' }}>
            Hi, {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'grey.600' }}>
            Need help?
            <br /> Please check our docs
          </Typography>
          <Button fullWidth to={PATH_DOCS.root} variant="contained" component={RouterLink}>
            Documentation
          </Button>
        </DocStyle>
      </Box> */}
    </Scrollbar>
  );
  const renderContentCompact = (
    <Scrollbar
    sx={{ height: '100%', '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' } }}
  >
    <Box sx={{ my: 3,}}>
      <Link underline="none" component={RouterLink} to={ROOTS_PCB}>
        <AccountStyle>
          <MyAvatar />
          {/* <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {userData.first_name
                ?
                (userData.first_name + ' ' + userData.last_name)
                :
                userData.company_name
              }
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {userData.isVendor ? 'Vendor' : userData.user_role}
            </Typography>
          </Box> */}
        </AccountStyle>
      </Link>
    </Box>

    {/* <NavSectionCompact navConfig={sidebarConfig} isCompact={true}  sx={{ pb: 2 }} /> */}
       <NavSection navConfig={sidebarConfig} isCompact={true}  />
    <Box sx={{ flexGrow: 1 }} />

  </Scrollbar>
  )

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

           {/*sidebar for desktop*/}
      <MHidden width="lgDown">
        <Drawer
          open={!isCompact}
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' },
          }}
        >
          {renderContent}
          <Stack flexDirection={'row'}>
          <Stack>
            <Switcher onClick={handleCompact}>
              <Icon
                className="chev-icon chev-left"
                width={30}
                height={30}
                icon={chevronLeft}
              />
              <Icon
                className="chev-icon chev-left"
                width={30}
                height={30}
                icon={chevronLeft}
              />
              <Icon
                className="chev-icon chev-left"
                width={30}
                height={30}
                icon={chevronLeft}
              />
            </Switcher>
          </Stack>
          <Stack alignItems={'center'} justifyContent={'center'}>
            <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
              V {VERSION}
            </Typography>
          </Stack>
          </Stack>
        </Drawer>
      </MHidden>

      {/* <MHidden width="lgDown">
        <Drawer
          open={!isCompact}
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
          <Switcher onClick={handleCompact} isCompact={true}>
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
          </Switcher>
        </Drawer>
      </MHidden> */}
            <MHidden width="lgDown">
        <Drawer
          open={isCompact}
          variant="persistent"
          PaperProps={{
            elevation: 10,
            sx: {
              width: DRAWER_WIDTH_COMPACT,
              bgcolor: 'background.default',
              height: '95vh',
              top: theme.spacing(2),
              bottom: theme.spacing(2),
              left: theme.spacing(2),
              border: 'none !important',
              borderRadius: 2,
            },
          }}
        >
          {renderContentCompact}
          <Switcher onClick={handleCompact} isCompact={true}>
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
            <Icon
              className="chev-icon chev-right"
              width={30}
              height={30}
              icon={chevronRight}
            />
          </Switcher>
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
