import moment from "moment"

export const getStatusColorAndLabel = (status) => {
    switch (status) {
      case 1:
        return { color: 'warning', label: 'Status: Draft' }
      case 2:
        return { color: 'info', label: 'Status: Open' }
      case 3:
        return { color: 'primary', label: 'Status: Evaluating' }
      case 4:
        return { color: 'success', label: 'Status: Accepted' }
      case 5:
        return { color: 'warning', label: 'Status: Closed' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }

  export const getAgoString = (startDate) => {
    const now = moment();
    const startMoment = moment.unix(startDate);
    const diffInSeconds = now.diff(startMoment, 'seconds');
  
    if (diffInSeconds < 5) {
        return 'Just now';
    } else if (diffInSeconds < 60) {
        return `${diffInSeconds} ${diffInSeconds === 1 ? 'second' : 'seconds'} ago`;
    } else if (diffInSeconds < 3600) {
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        return `${diffInMinutes} ${diffInMinutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (diffInSeconds < 86400) {
        const diffInHours = Math.floor(diffInSeconds / 3600);
        return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'} ago`;
    } else if (diffInSeconds < 2592000) {
        const diffInDays = Math.floor(diffInSeconds / 86400);
        return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'} ago`;
    } else if (diffInSeconds < 31536000) {
        const diffInMonths = Math.floor(diffInSeconds / 2592000);
        return `${diffInMonths} ${diffInMonths === 1 ? 'month' : 'months'} ago`;
    } else {
        const diffInYears = Math.floor(diffInSeconds / 31536000);
        return `${diffInYears} ${diffInYears === 1 ? 'year' : 'years'} ago`;
    }
  } 


  export const getStatusActivation = (status) => {
    switch (status) {
      case 1:
        return { color: 'primary', label: 'Status: Active' }
      case 4:
        return { color: 'primary', label: 'Status: Active' }
      case 9:
        return { color: 'primary', label: 'Status: Active' }
      case 2:
        return { color: 'error', label: 'Status: Delete' }
      case 0:
        return { color: 'warning', label: 'Status: Inactive' }
      case 8:
        return { color: 'warning', label: 'Status: Inactive' }
      case 10:
        return { color: 'warning', label: 'Status: Inactive' }
    }
  }

  export const getStatusColorTicket = (status) => {
    switch (status) {
      case 1:
        return { color: 'default', label: 'Status: New' }
      case 2:
        return { color: 'info', label: 'Status: In Progress' }
      case 3:
        return { color: 'success', label: 'Status: Completed' }
      case 4:
        return { color: 'primary', label: 'Status: In Testing' }
      case 5:
        return { color: 'error', label: 'Status: Cancelled' }
      case 6:
        return { color: 'error', label: 'Status: Rejected' }
      default:
        return { color: 'default', label: 'Status: Open' }
    }
  }

  export const getStatusForRoutes = (status) => {
    switch (status) {
      case 1:
        return { color: 'secondary', label: 'Staged' };
      case 2:
        return { color: 'warning', label: 'Installing' };
      case 3:
        return { color: 'primary', label: 'Delivered' };
      case 4:
        return { color: 'success', label: 'Completed' };
      case 5:
        return { color: 'error', label: 'Returned' };
      case 6:
        return { color: 'info', label: 'Assigned' };
      default:
        return { color: 'default', label: 'Staged' };
    }
  }
