import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Place } from '@material-ui/icons';

const LocationDetails = ({ id, name, lat, lng,index,address,city,state,zipcode,installationAddress }) => {
  const theme = useTheme()
  return (
    <div draggableId={installationAddress} style={{ border: '1px solid black', padding: '10px', margin: '5px', width: '200px' }}>
      <div
        style={{
          backgroundColor: '#e32929', // '#FF0060',
          color: '#000000',
          display: 'inline-block',
          padding: '5px 10px',
          borderRadius: '5px',
          marginRight: '5px',
          fontSize: '14px',
          fontWeight: 'bold',
        }}
      >
        {/* <Place sx={{ width: 25, height: 25, color:'#e32929' }} /> */}
        <h3 style={{ margin: 0 }}>{index+1}</h3>
      </div>
      <h3 style={{width:'200px',wordWrap:'break-word',color:theme.palette.primary.main}}>{name}</h3>
      <p style={{width:'200px',wordWrap:'break-word',}}>{address}</p>
      <p style={{width:'200px',wordWrap:'break-word',}}>{city}</p>
      <p style={{width:'200px',wordWrap:'break-word',}}>{state}</p>
      <p style={{width:'200px',wordWrap:'break-word',}}>{zipcode}</p>
      <p style={{width:'200px',wordWrap:'break-word',}}>Unique Id :{id}</p>
      {/* <p style={{color:theme.palette.secondary}}>Latitude: {lat}</p>
      <p style={{color:theme.palette.secondary}}>Longitude: {lng}</p> */}
    </div>
  );
};

export default LocationDetails;
