import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import roundSend from '@iconify/icons-ic/round-send';
import heartFill from '@iconify/icons-eva/heart-fill';
import shareFill from '@iconify/icons-eva/share-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import phoneFill from '@iconify/icons-eva/phone-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-fill';
import { MIconButton } from 'src/components/@material-extend/index.js';
import roundAddPhotoAlternate from '@iconify/icons-ic/round-add-photo-alternate';
// material
import {
  Box,
  Link,
  Card,
  Stack,
  Paper,
  Avatar,
  TextField,
  Typography,
  CardHeader,
  IconButton,
  AvatarGroup,
  InputAdornment,
  FormControlLabel
} from '@material-ui/core';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber } from '../../../../utils/formatNumber';
// hooks
import useAuth from '../../../../hooks/useAuth';
//
import MyAvatar from '../../../MyAvatar';
import EmojiPicker from '../../../EmojiPicker';
import { MCheckbox } from '../../../@material-extend';
import axios from 'axios';
import { REST_API } from 'src/constants/Defaultvalues';
import { useSnackbar } from 'notistack';
import moment from 'moment';

// ----------------------------------------------------------------------

// Messages.propTypes = {
//   post: PropTypes.object
// };

const predefinedComment = [
    {
      id: 1,
      author: {
        name: 'John Doe',
        avatarUrl: '/static/mock-images/avatars/avatar_default1.jpg',
      },
      createdAt: new Date().toISOString(),
      message: 'Request has been accepted.'
    },
    {
      id: 2,
      author: {
        name: 'Jane Smith',
        avatarUrl: '/static/mock-images/avatars/avatar_2.jpg',
      },
      createdAt: new Date().toISOString(),
      message: 'Purchase Order request has been updated.',
    },

    {
        id: 3,
        author: {
          name: 'John Doe',
          avatarUrl: '/static/mock-images/avatars/avatar_default1.jpg',
        },
        createdAt: new Date().toISOString(),
        message: 'Request has been Reopened.',
      },

      {
        id: 4,
        author: {
        name: 'Jane Smith',
        avatarUrl: '/static/mock-images/avatars/avatar_2.jpg',
      },
      createdAt: new Date().toISOString(),
      message: 'Purchase Order request has been updated.',
      },
  ];

export default function Messages({ name, profilePic, phone, id, isClient=false,isContact=false,isLead=false }) {
  // const { user } = useAuth();
  const clientId = id;
  const commentInputRef = useRef(null);
  const fileInputRef = useRef(null);
  // // const [isLiked, setLiked] = useState(post.isLiked);
  // // const [likes, setLikes] = useState(post.personLikes.length);
  const [message, setMessage] = useState('');
  const [fetchStatus, setFetchStatus] = useState(false);
  const [displayedComments, setDisplayedComments] = useState([]);
  const User = JSON.parse(localStorage.getItem('user'))

  const stackRef = useRef();

  useEffect(() => {
    stackRef.current.scrollTop = stackRef.current.scrollHeight;
  }, [displayedComments]);

  const fetchAllSms = async () =>{
    try{
      setFetchStatus(true)
      const response = await axios.post(`${REST_API}get-SMS-logs`,{phone:'+'+phone})
      if(response.data){
        if(response.data.result){
          console.log("fetchAll messages response",response.data)
          // setPredefinedComments(response.data.result)
          // let data = response.data.result;
          // initialComments = data.slice(0, 4);
          // remainingComments = data.slice(4);
          // setDisplayedComments(initialComments)
          setDisplayedComments(response.data.result)
        }
      }
      setFetchStatus(false)
    }
    catch(error){
      setDisplayedComments([])
      console.log(error)
    }
  }

  function isMilliseconds(timestamp) {
    return timestamp > 9999999999;
  }

  useEffect(()=>{
    fetchAllSms();
  },[])

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleClickAttach = () => {
    fileInputRef.current.click();
  };

  const handleClickComment = () => {
    commentInputRef.current.focus();
  };

  // const [showAll, setShowAll] = useState(false);

  // const handleLoadMore = () => {
  //   setDisplayedComments(predefinedComments);
  //   setShowAll(true);
  // };

  // const handleLoadLess = () => {
  //   setDisplayedComments(initialComments);
  //   setShowAll(false);
  // };

  const currentDate = new Date().toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long' ,
    year: 'numeric',
  });
  return (
    <Card>
      <CardHeader
        disableTypography
        // avatar={<MyAvatar />}
        avatar={<Avatar
        alt={name}
        src={profilePic || 'avatar.jpg'}
        sx={{
          // mx: 'auto',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'common.white',
          width: { xs:50 },
          height: { xs:50 }
        }}
      />}
        title={
          <Link to="#" variant="subtitle2" color="text.primary" component={RouterLink}>
            {name && name}
          </Link>
        }
        subheader={
          <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
            {currentDate}
          </Typography>
        }
        // action={
        //   <>
        //     <MIconButton>
        //       <Icon icon={phoneFill} width={20} height={20} />
        //     </MIconButton>
        //     <MIconButton>
        //       <Icon icon={videoFill} width={20} height={20} />
        //     </MIconButton>
        //     <MIconButton>
        //       <Icon icon={moreVerticalFill} width={20} height={20} />
        //     </MIconButton>
        //   </>
        // }
      />

      <Stack ref={stackRef} spacing={3} sx={{ p: 1,overflowY: 'auto', minHeight:'330px' ,maxHeight: '330px' }}>
           <Box />
          <Stack spacing={3} sx={{ p: 1 }}>
        {displayedComments?.filter(data => (isContact ? data.contact_id === clientId : (isLead ? data.lead_id === clientId : data.partner_id === clientId)) || data.isFetched).length === 0 ? (
          <Stack
          direction="row"
          spacing={2}
          justifyContent='center'
          marginBottom={5}
          marginTop={2}
          alignItems="center"
        >
            {
              fetchStatus ?
              <CircularProgress
                color="primary"
                variant="indeterminate"
              />
              : <Typography variant="h6" color="text.secondary">
                No messages
              </Typography>
            }
            </Stack>
          ) : 
        displayedComments?.filter(data => (isContact ? data.contact_id === clientId : (isLead ? data.lead_id === clientId : data.partner_id === clientId)) || data.isFetched)
        // .sort((a, b) => b.date_created - a.date_created)
        .sort((a, b) => {
          const dateA = a.date_created * (a.date_created < 9999999999 ? 1000 : 1);
          const dateB = b.date_created * (b.date_created < 9999999999 ? 1000 : 1);
          return dateA - dateB;
        })
        .map((comment) => (
          <Stack
            key={comment.id}
            direction="row"
            spacing={2}
            justifyContent={isClient ? (comment.direction === 'outbound-api') ? 'flex-start' : 'flex-end' : (comment.direction === 'outbound-api') ? 'flex-end' : 'flex-start'}
            alignItems="flex-start" // Adjust the alignment as needed
          >
            {(comment.direction !== 'outbound-api') &&
              <Avatar
                alt={(comment.isFetched && isClient) ? comment.to : (comment.isFetched ? User.person_name : comment.partner_name)}
                src={(User.profile_pic || (isContact ? comment.contact_name : (isLead ? comment.lead_name : comment.partner_name)))}
                sx={{
                  marginTop:0.5,
                  width: { xs:37 },
                  height: { xs:37 }
                }}
              />}
            <Paper
              sx={{
                p: '12px 16px', // Adjust the padding as needed
                maxWidth: 300, // Adjust this value as needed
                bgcolor: (comment.direction === 'outbound-api') ? '#DCF8C6' : '#ECE5DD', //DCF8C6 Adjust colors based on the sender
                color: (comment.direction === 'outbound-api') ? '#000' : '#000', // Adjust the text colors as needed
                borderRadius: '12px',
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 0.5 }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: '#000',mr:3 }}
                >
                  {(comment.isFetched && isClient) ? comment.to : (comment.isFetched ? User.person_name : comment.partner_name)}
                </Typography>
                <Typography variant="caption" sx={{ color: '#6B6969' }}>
                  {
                    comment.date_created > 9999999999 ? 
                    moment(comment.date_created).format('MM-DD-YYYY HH:mm') : 
                    moment.unix(comment.date_created).format('MM-DD-YYYY HH:mm')}
                </Typography>
              </Stack>
              <Typography
                variant="body2"
                sx={{
                  maxWidth: '100%', // Adjust this value as needed
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal', // Adjust the white space as needed
                  color: '#000', // Adjust the text color for the message as needed
                }}
              >
                {comment.message_body}
              </Typography>
            </Paper>
            {(comment.direction === 'outbound-api') && (
              <Avatar
              alt={(comment.isFetched && isClient) ? comment.to : (comment.isFetched ? User.person_name : (isContact ? comment.contact_name : (isLead ? comment.lead_name : comment.partner_name)))}
              src={isContact ? comment.contact_name : (isLead ? comment.lead_name : comment.partner_name)} />
            )}
          </Stack>
        ))}
      </Stack>
      
      {/* <Stack direction="row" alignItems="center" sx={{marginBottom: '-20px'}}>
          <MyAvatar />
          <TextField
            fullWidth
            size="small"
            value={message}
            inputRef={commentInputRef}
            placeholder="Write a Message…"
            onChange={handleChangeMessage}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleClickAttach}>
                    <Icon icon={roundAddPhotoAlternate} width={24} height={24} />
                  </IconButton>
                  <EmojiPicker alignRight value={message} setValue={setMessage} />
                </InputAdornment>
              )
            }}
            sx={{
              ml: 2,
              mr: 1,
              '& fieldset': {
                borderWidth: `1px !important`,
                borderColor: (theme) => `${theme.palette.grey[500_32]} !important`
              }
            }}
          />
          <IconButton>
            <Icon icon={roundSend} width={24} height={24} />
          </IconButton>
          <input type="file" ref={fileInputRef} style={{ display: 'none' }} />
        </Stack> */}
        
      </Stack>

      
    </Card>
  );
}
