import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Slide,
  Stack,
  Box,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
  TextField,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { forwardRef, useState } from 'react'
import { MRadio } from './@material-extend'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function ConfirmationPopup({
  open,
  handleClose,
  message,
  handleClick,
  loading2,
  isComplete,
  setCompleteStatus,
  shippingReturn,
  templates,
  isPickup,
  selectTemplate,
  setSelectTemplate,
  selectedInstallationIds
}) {
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const navigate = useNavigate()
    const [value, setValue] = useState('1');
  
    const handleChange = (event) => {
      setValue(event.target.value);
      console.log('ghghfghfghfghfg',event.target.value);
      setCompleteStatus(event.target.value)
    };

  return (
    <>
     {!isComplete ? (
      <Dialog
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        transitionDuration={500}
        sx={{ minWidth: {xs:0 , sm: 600} }}
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent sx={{ minWidth: 270, mt: -2 }}>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        {
          isPickup?
        
          <DialogContentText id="alert-dialog-description">
            <Autocomplete
                         fullWidth
                         options={templates || []}
                         filterSelectedOptions
                         value={selectTemplate}
                         onChange={(event, newValue) => {  
                          setSelectTemplate(newValue)
                         }}
                         getOptionLabel={(option) => option.name}
                         renderInput={(params) => (
                           <TextField
                             {...params}
                             label="Select Template"
                             placeholder="Select Template"
                             size="small"
                           />
                         )}
                       />
          </DialogContentText>
          :""
                        }
        </DialogContent>
        {/* <DialogActions sx={{ justifyContent: 'flex-end !important' }}> */}
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          px={3}
          mb={2.5}
          mt={-1}
        >
          <Button
            size="medium"
            onClick={() => {
              handleClose()
              enqueueSnackbar('Operation was cancelled', { variant: 'error' })
            }}
            sx={{
              background: '#BB2525',
              boxShadow: `0 8px 16px 0 ${alpha('#BB2525', 0.24)}`,
              '&:hover': {
                background: '#FF8F8F',
                boxShadow: `0 8px 16px 0 ${alpha('#FF8F8F', 0.24)}`,
              },
            }}
          >
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="overline"
            >
              Cancel
            </Typography>
          </Button>
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              if(isPickup && !selectTemplate){
                return enqueueSnackbar('Select a Template', { variant: 'error' })
              }else if(isPickup && selectTemplate){
                navigate(`${PATH_DASHBOARD.installation.templateview}/${selectTemplate.id}`,{
                  state: { selectedInstallationIds: selectedInstallationIds,template_id : selectTemplate.i,isPickup:true}
                },
                  
                )
              }else{
                handleClick()
              }
            }}
            sx={{ color: theme.palette.primary.main }}
            disabled={loading2}
          >
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="overline"
            >
              {loading2 ? <CircularProgress size={24} color="inherit" /> : 'Yes'}
            </Typography>
          </Button>
        </Stack>
        {/* </DialogActions> */}
      </Dialog>
      ):(
        <Dialog
        aria-describedby="alert-dialog-slide-description"
        TransitionComponent={Transition}
        transitionDuration={500}
        sx={{ minWidth: {xs:0 , sm: 600},padding:'5px' }}
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <DialogTitle>Please Choose from the following</DialogTitle>
        <DialogContent sx={{ minWidth: 270, mt: -2 }}>
          <DialogContentText id="alert-dialog-description">
            {/* {message} */}
            <FormControl component="fieldset">
              <RadioGroup value={value} onChange={handleChange}>
                <FormControlLabel value="1" control={<Radio />} label="Installs" />
                <FormControlLabel value="2" control={<Radio />} label="Pickup" />
                <FormControlLabel value="3" control={<Radio />} label="Shipping" />
              </RadioGroup>
            </FormControl>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions sx={{ justifyContent: 'flex-end !important' }}> */}
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          px={3}
          mb={2.5}
          mt={-1}
        >
          <Button
            size="medium"
            onClick={() => {
              handleClose()
              enqueueSnackbar('Operation was cancelled', { variant: 'error' })
            }}
            sx={{
              background: '#BB2525',
              boxShadow: `0 8px 16px 0 ${alpha('#BB2525', 0.24)}`,
              '&:hover': {
                background: '#FF8F8F',
                boxShadow: `0 8px 16px 0 ${alpha('#FF8F8F', 0.24)}`,
              },
            }}
          >
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="overline"
            >
              Cancel
            </Typography>
          </Button>
          <Button
            size="medium"
            variant="contained"
            onClick={() => {
              handleClick()
            }}
            sx={{ color: theme.palette.primary.main }}
            disabled={loading2}
          >
            <Typography
              sx={{ color: theme.palette.common.white }}
              variant="overline"
            >
              {loading2 ? <CircularProgress size={24} color="inherit" /> : 'Yes'}
            </Typography>
          </Button>
        </Stack>
        {/* </DialogActions> */}
      </Dialog>)}
    </>
  )
}
